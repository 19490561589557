import * as React from 'react';
import classnames from 'classnames';

type Props = React.HTMLProps<HTMLButtonElement> & {
  type?: 'green' |'lightgreen' | 'darkgreen' | 'brokengreen' | 'grey' | 'lightgrey' | 'white' | 'red' | 'orange' | 'none',
  radius?: 'rounded' | 'right' | 'left' | 'sm' | 'lg',
  padding?: 'px' | 'px-1' | 'top',
  width?: 'none',
  margin?: 'xy' | 'top' | 'none' | 'auto' | 'y',
  position?: 'absolute',
  border?: 'green' | 'grey' | 'darkgrey' | 'orange' | 'border-b', 
  textsize?: 'xs',
  button_type?: 'submit'
  weight?: 'semibold' | 'bold' 
}

let getTypeClass = (type: Props['type']) => {
  switch (type) { 
    case 'none':
      return 'text-black'
    case 'orange':
      return 'text-getAllOrange-400 bg-white' 
    case 'white':
      return 'text-getAllGrey-101 bg-white'
    case 'red':
      return 'text-white bg-getAllRed-100'
    case 'lightgrey':
      return 'text-black bg-getAllGrey-107 hover:bg-getAllGrey-100';
    case 'grey':
      return 'text-white bg-getAllGrey-500 hover:bg-getAllGrey-400'
    case 'brokengreen':
      return 'text-white bg-getAllGreen-600 hover:bg-getAllGreen-300';
    case 'darkgreen':
      return 'text-white bg-getAllGreen-500 hover:bg-getAllGreen-300';
    case 'lightgreen':
      return 'text-white bg-getAllGreen-100 hover:bg-getAllGreen-300';
    case 'green':
      return 'text-white bg-getAllGreen-200 hover:bg-getAllGreen-100';
    default:
      return 'text-white bg-getAllGreen-100';
  }
}

let getRadius = (radius: Props['radius']) => {
  switch (radius) { 
    case 'left':
      return 'rounded-l rounded-r-none'
    case 'right':
      return 'rounded-r rounded-l-none' 
    case 'lg':
      return 'rounded-lg'; 
    case 'sm':
      return 'rounded-sm'; 
    case 'rounded':
      return 'rounded'; 
    default:
      return 'rounded-none';
  }
}

let getWidth = (width: Props['width']) => {
  switch (width) {  
    case 'none':
      return ''; 
    default:
      return 'w-full';
  }
}

let getPadding = (padding: Props['padding']) => {
  switch (padding) { 
    case 'px-1':
      return 'px-7 py-1';  
    case 'top':
      return 'px-2 pb-1 pt-6'; 
    case 'px':
      return 'px-5 py-2'; 
    default:
      return 'py-1';
  }
} 

let getMargin = (margin: Props['margin']) => {
  switch (margin) {
    case 'auto':
      return  'my-auto mx-1';  
    case 'none':
      return  'm-0';
    case 'top':
      return  'mt-1';
    case 'y':
      return 'mx-2';
    case 'xy':
      return 'm-5';
    default:
      return 'my-4';
  }
}  

let getPosition = (position: Props['position']) => {
  switch (position) {   
    case 'absolute':
      return 'absolute inset-y-0 right-0';
    default:
      return '';
  }
} 

let getBorder = (border: Props['border']) => {
  switch (border) {   
    case 'border-b':
      return 'border-b-2 border-getAllGreen-200'; 
    case 'orange':
      return 'border border-getAllOrange-400';
    case 'darkgrey':
      return 'border border-getAllGrey-200';
    case 'grey':
      return 'border border-getAllGrey-100 hover:border-getAllGreen-200';
    case 'green':
      return 'border-getAllGreen-200';
    default:
      return 'border-none';
  }
} 
 


let getFontWeight = (weight: Props['weight']) => {
  switch (weight) {   
    case 'semibold':
      return 'font-semibold';
    case 'bold':
      return 'font-bold';
    default:
      return 'font-normal';
  } 
}

let getTextSize = (textsize: Props['textsize']) => {
  switch (textsize) {   
    case 'xs':
      return 'text-xs';
    default:
      return '';
  } 
}
 
export const Button = ({ type, radius, margin, children, padding, width, position, border, weight, textsize, button_type, ...props}: Props) => { 
  return <button className={classnames(getPosition(position), getBorder(border), getPadding(padding), 
          getTextSize(textsize), getTypeClass(type), getRadius(radius), getWidth(width), 
          getMargin(margin), getFontWeight(weight))}
            type={button_type} { ...props }
        >{children}
        </button>
}

export const ModalCloseButton = ({type, width, children, ...props}: Props) => {
  return <div className={classnames("absolute", getWidth(width))}>
    <button className={classnames("float-right leading-none font-semibold outline-none m-auto ml-auto px-3 py-2",
    )} {...props } >{children}
  </button></div>
}
 