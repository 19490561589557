/* eslint-disable jsx-a11y/alt-text */
import { Link } from 'react-router-dom'
import { ColumnFilter } from "../../../components/ColumnFilter";  
import DeleteCategory from '../DeleteCategory';

export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
    },
    {
        accessor: 'name',
        Header: 'Category Name',
        Filter: ColumnFilter,
        isVisible: true,        
    },
    {
        accessor: 'thumbnail_image',
        Header: 'Cover Image',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
        Cell: (props) => {
            return (<> 
                <img className='column-image'
                     src={props.row.original.thumbnail_image}/>
            </>); 
        }
    },
    {
        accessor: 'banner_image',
        Header: 'Banner',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
        Cell: (props) => {
            return (<> 
                <img className='column-image'
                     src={props.row.original.banner_image}/>
            </>); 
        }
    }, 
    {
        accessor: 'action',
        Header: 'Action',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
        Cell: data => <div className="flex justify-center">
                {/* <Link to={`/category/disable/${data.row.original.id}`} className="disable">Disabled</Link> */}
                <Link to={`/category/edit/${data.row.original.id}`} className="edit">Edit</Link>
                <DeleteCategory id={data.row.original.id}/>
            </div> 
    }
]