/* eslint-disable eqeqeq */
import api from './api'
// import Swal from 'sweetalert2'

let authenticate = async (email, password) => {
    // try {
        let result = await api.post('login', { email, password });

        if (result.status == 200) {
            return true
        } else {
            return false;
        }
    }
    // } catch (error) {
    //     // let message = 'Something went wrong!';
    //     // Swal.fire({
    //     //     icon: 'error',
    //     //     title: 'Oops...',
    //     //     text: message,
    //     // }) 

    //     return error;
    // }

// }

let createSubCategory = async (subcategory, category) => {
    // try {
        let result = await api.post('save-subcategory', { subcategory, category });
        console.log(result)

        if (result.status == 200) {
            return result.data;
        } else {
            return false;
        }}
        // if (result.status == 201) {
        //     Swal.fire("Saved", "SubCategory successfully saved")
        // }
    // } catch (error) {
        // let message = 'Something went wrong!';
        // Swal.fire({
        //     icon: 'error',
        //     title: 'Oops...',
        //     text: message,
        // })

//         return false;
//     }

// }

let updateSubCategory = async (id, name, category_name) => {
    // try {
        let result = await api.put(`update-subcategory/${id}`, { name, category_name });
        console.log(result)
        if (result.status == 200) {
            return result.data;
            // Swal.fire("Updated", "SubCategory successfully updated")
        } else {
            return false;
        }
    // } catch (error) {
        // let message = 'Something went wrong!';
        // Swal.fire({
        //     icon: 'error',
        //     title: 'Oops...',
        //     text: message,
        // })

        

}

export {
    authenticate,
    createSubCategory,
    updateSubCategory
}