import {useState, useEffect} from 'react'
import { ModalContainer } from '../../../components/common/Container'
import { Button } from '../../../components/common/Button' 
import { Span } from '../../../components/common/Span'  
import api from '../../../network/api' 
import { useHistory } from 'react-router-dom'
import question from '../../../images/question.png'
    
type Props = {
    setDeleteModal: Function,
    id: any 
} 
  
export default ({setDeleteModal, id, ...props}: Props) => {
 
    
    const [error, setError]= useState(null);  
    const history = useHistory(); 
    const onSubmit = (e) => {
        e.preventDefault(); 

        api.delete(`remove-subcategory/${id}`)
        .then(res => { 
            history.push("/subcategory");
            window.location.reload();
        }).catch(error => {
            // Swal.fire("Error", error, 'error')
            setError(error.message);
        })
        setDeleteModal(false)
    }
      
    return (<>   
    <ModalContainer bg='white' radius='rounded'> 
        
        <div className="px-20 py-4 whitespace-normal text-center "> 
            <div className='mt-5 mb-2.5'>
                <img src={question} className='h-16 my-2 mx-auto'/> 
                <Span size='2xl' weight='bold'>Are you sure?</Span>
            </div>
            <Span size='base'>
                Want to delete this Sub Category
            </Span> 
            
            <div className='text-center'> 
                <Button width='none' padding='px' type='white' border='grey' radius='rounded'
                    onClick={() => setDeleteModal(false)}
                >
                    No
                </Button>  
                
                <Button width='none' padding='px' type='lightgreen' border='green'  margin='y' radius='rounded'
                 onClick={(e) => onSubmit(e)}>
                    Yes
                </Button>
            </div> 
        </div> 
    </ModalContainer> 

  <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
  </>);
}   