const ORDER_STATUS = {
    new_order: "New Order",
    processing: "Processing",
    for_delivery: "For Delivery",
    delivered: "Delivered",
    cancelled: "Cancelled"
}

export {
    ORDER_STATUS
}
