import React from 'react'
import { MainContent } from '../components/common/Container'
import ProductListEditPage from '../containers/ProductList/ListEditPage'  
import Navbar from '../containers/Navbar'
import Sidebar from '../containers/Sidebar'
import { useParams } from 'react-router-dom'

 
const ListEdit = () => {
    let params:any = useParams();

    return ( 
        <> 
            <Sidebar/>
            <MainContent>
                <Navbar /> 
                <ProductListEditPage id={params.id}/>
            </MainContent>
        </>
    )
}

export default ListEdit
