import { useState, useEffect } from "react";
import { CenterContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading" 
import { Span } from "../../components/common/Span"  
import { GridContainer, InputContainer, Container } from '../../components/common/Container';  
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button'
import { Link } from 'react-router-dom'

import img from '../../images/img.jpg'

import UpdateModal from './Modal/UpdateModal'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import api from '../../network/api'
// import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom"; 
   
const validationSchema = Yup.object({ 
  name: Yup.string().required('Required!')
}) 
  

export default ({id, ...props}:any) => {  
    
    const history = useHistory();

    const [initialValues, setData] = useState({
        name: '',
    })

    // Upload image 
    const [bannerMarketEdit,setBannerMarketEdit] = useState("")
    const [previewBannerMarketEdit,setPreviewBannerMarketEdit] = useState("")  
    // const [imgMarketEdit,setImgMarketEdit] = useState(true)
    const [fileMarketEdit, setFileMarketEdit] = useState('Choose File');

    const handleBannerMarketEdit = (e) => {
        e.preventDefault(); 
        setFileMarketEdit(e.target.files[0].name);
        const selectedBannerMarket = e.target.files[0]
        setBannerMarketEdit(selectedBannerMarket)
        const bannerMarketPreview = URL.createObjectURL(selectedBannerMarket)
        setPreviewBannerMarketEdit(bannerMarketPreview)
        // setImgMarketEdit(false)
    } 

    // Formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues, 
        onSubmit: () => {},
        validationSchema
    })

    // Get Data 
    const [error, setError]= useState(null);
    useEffect(() => {
        api.get(`get-one-market/${id}`)
        .then(res => {
            let {name, banner_image } = res.data.data
            setData({
                name: res.data.data.name
            })

            setPreviewBannerMarketEdit(banner_image)
            setBannerMarketEdit(banner_image) 
        })
      .catch(error => {
                setError(error.message);
            })
      }, [])

    // Submit
    const onSubmit = (e) => {
        e.preventDefault();
        
        console.log('Form data', formik.values.name)

        let formData = new FormData();
        formData.append('market',  formik.values.name) 
        formData.append('banner_image', bannerMarketEdit)
        api.put(`update-market/${id}`,formData).then(res => { 
            history.push("/market");
            window.location.reload(); 
        }).catch(error => {
            setError(error.message);
        }) 
    }

    const [updateModal, setUpdateModal] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault(); 
        setUpdateModal(true);
    }

    return (<>
    <main>
        <CenterContainer padding="header">
            <H1 weight='medium'>Edit Market</H1> 

            <div className="toggle-container"> 
                <Span weight="semibold">Market Details</Span>
            </div>
             
            
        <Container bg='white' padding="x">
            <GridContainer bg='white' padding='top-sm' grid="two">
                <div>
                    <Span>Name</Span>
                    <Input type='text' radius='rounded' placeholder='Enter mommyMili market'
                        id='name'
                        {...formik.getFieldProps('name')}/>
                    {formik.touched.name && formik.errors.name? <div className="error">{formik.errors.name}</div> : null}
                </div>  
                <div>
                    <Span>Banner Image</Span>   
                    <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'> 
                        <Input type='text' border='none' radius='rounded' id='banner_image' value={fileMarketEdit} disabled/>
                        <span className="browse">
                            <input type="file" name="banner" id="upload_banner" onChange={(e) => handleBannerMarketEdit(e)} accept="image/*"/>
                            <label htmlFor="upload_banner" className="cursor-pointer">Browse</label>
                        </span>
                    </InputContainer>
                     
                    {bannerMarketEdit && <img src={previewBannerMarketEdit} alt='' className='banner'/>}   
                </div>
            </GridContainer>

            <div className='text-right'>
                <Button 
                    width='none' padding='px' type='lightgreen' margin='xy' radius='rounded' 
                    onClick={handleSubmit}  
                    // disabled={!(formik.dirty && formik.isValid)}
                >
                    Update
                </Button>
                <Link  to="/market">
                    <Button width='none' padding='px' type='grey' radius='rounded'>Cancel</Button>
                </Link>
            </div>
        </Container>
 
        </CenterContainer> 

        {updateModal &&
            <UpdateModal setUpdateModal={setUpdateModal} onSubmit={onSubmit}/>
        } 
    </main>
    </>)
}