/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from 'react'  
import { GridContainer, InputContainer, Container } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button'   
import { useHistory } from "react-router-dom"; 

import * as Yup from 'yup'
import { useFormik } from 'formik'
import api from '../../network/api'
import { Link } from 'react-router-dom'; 
import UpdateModal from './Modal/UpdateModal'
  

const validationSchema = Yup.object({ 
    name: Yup.string().required('Required!'), 
}) 


export default ({id, ...props}:any) => { 

    // Upload image 
    const [initialValues, setData] = useState({
        name: '',
    })
    const [coverEdit,setcoverEdit] = useState({} as any)
    const [previewcoverEdit,setPreviewcoverEdit] = useState("") 
    const [fileCtgCoverEdit, setFileCtgCoverEdit] = useState(""); 

    const [bannerEdit,setbannerEdit] = useState({} as any)
    const [previewbannerEdit,setPreviewbannerEdit] = useState("") 
    const [fileCtgBannerEdit, setFileCtgBannerEdit] = useState("");

    const [error, setError]= useState(null);


    // find - list of data
    // get - one data
    // update - update one data
    // create - create data
    // delete - delete data

    
    useEffect(() => {
        api.get(`get-one-category/${id}`)
        .then(res => {

            let {name,thumbnail_image, banner_image } = res.data.data
            setData({
                name: res.data.data.name
            })

            setPreviewbannerEdit(banner_image)
            setbannerEdit(banner_image)
            setPreviewcoverEdit(thumbnail_image)
            setcoverEdit(thumbnail_image)
        })
      .catch(error => {
                setError(error.message);
            })
      }, [])

    const handlecoverEdit = (e) => {
        e.preventDefault();
        setFileCtgCoverEdit(e.target.files[0].name);
        const selectedcoverEdit = e.target.files[0]
        setcoverEdit(selectedcoverEdit)
        const coverEditPreview = URL.createObjectURL(selectedcoverEdit)
        setPreviewcoverEdit(coverEditPreview)
    } 

    const handlebannerEdit = (e) => {
        e.preventDefault(); 
        setFileCtgBannerEdit(e.target.files[0].name);
        const selectedbannerEdit = e.target.files[0]
        setbannerEdit(selectedbannerEdit)
        const bannerEditPreview = URL.createObjectURL(selectedbannerEdit)
        setPreviewbannerEdit(bannerEditPreview)
    } 
    
    // Formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues, 
        onSubmit: () => {},
        validationSchema
    })


    const history = useHistory();
    const onSubmit = (e) => {
        e.preventDefault();
        // alert('test')

        // formik.values.name
        console.log('Form data', formik.values.name)

        let formData = new FormData();
        formData.append('category',  formik.values.name)
        formData.append('thumbnail_image', coverEdit)
        formData.append('banner_image', bannerEdit)
        api.put(`update-category/${id}`,formData).then(res => { 
            history.push("/category");
            window.location.reload(); 
        }).catch(error => {
            setError(error.message);
        }) 
    }

    const [updateModal, setUpdateModal] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault(); 
        setUpdateModal(true);
    }

    return (<>
    <form>
        <Container bg='white' padding="x">
            <GridContainer bg='white' padding='top-sm' grid='two'>
                <div className="col-span-2">
                    <Span>Category Name</Span>
                    <Input type='text' radius='rounded' placeholder='New Category'
                        {...formik.getFieldProps('name')}/>
                    {formik.touched.name && formik.errors.name? <div className="error">{formik.errors.name}</div> : null}
                </div> 
            </GridContainer>
            <GridContainer bg='white' padding='none' grid='two'>
                <div>
                    <Span>Cover Image</Span>  
                    <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'>
                        <Input type='text' border='none' radius='rounded' value={fileCtgCoverEdit} disabled/>
                        <span className="browse">
                            <input type="file" name="coverEdit" id="upload_coverEdit" onChange={(e) => handlecoverEdit(e)} accept="image/*"/>
                            <label htmlFor="upload_coverEdit" className="cursor-pointer">Browse</label> 
                        </span> 
                    </InputContainer>
                    
                    {/* <img src={img} alt='' className='coverCategory'/> */}
                    {coverEdit && <img src={previewcoverEdit} alt='' className='coverCategory'/>}   
                </div>
                <div>
                    <Span>Banner Image</Span>  
                    <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'>
                        <Input type='text' border='none' radius='rounded'  value={fileCtgBannerEdit} disabled/>
                        <span className="browse">
                            <input type="file" name="bannerEdit" id="upload_bannerEdit" onChange={(e) => handlebannerEdit(e)} accept="image/*"/>
                            <label htmlFor="upload_bannerEdit" className="cursor-pointer">Browse</label> 
                        </span>
                    </InputContainer>

                    {/* <img src={img} alt='' className='bannerCategory'/> */}
                    {bannerEdit && <img src={previewbannerEdit} alt='' className='bannerCategory'/>}   
                </div>
            </GridContainer>

            <div className='text-right'>
                <Button 
                    width='none' padding='px' type='lightgreen' margin='xy' radius='rounded' 
                    onClick={handleSubmit}
                    // disabled={!(formik.dirty && formik.isValid)}
                >
                    Update
                </Button>
                <Link  to="/category">
                    <Button width='none' padding='px' type='grey' radius='rounded'>Cancel</Button>
                </Link>
            </div>
        </Container>

        {updateModal &&
            <UpdateModal setUpdateModal={setUpdateModal} onSubmit={onSubmit}/>
        }
    </form>
    </>);
}