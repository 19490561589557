/* eslint-disable import/no-anonymous-default-export */
import { CenterContainer, CardContainer, GridContainer } from "../components/common/Container"; 
import { H1, H3 } from "../components/common/Heading"
import { Span } from "../components/common/Span"
import product from '../images/Totalproduct.png'
import subCategories from '../images/totalsubcategory.png'
import categories from '../images/categorylogo.png' 
import { useEffect, useState } from "react";
import api from "../network/api"; 
import { SpinnerCircularFixed } from 'spinners-react';
 
export default () => { 
    const [name, setName] = useState("")
    const [totalProducts, setNoOfProducts] = useState(0)
    const [totalCategory, setNoOfCategory] = useState(0)
    const [totalSubcategory, setNoOfSubCatefory] = useState(0)

    const locale = 'en';
    const [today, setDate] = useState(new Date());
    const day = today.toLocaleDateString(locale, { weekday: 'long' });
    const date = `${day}, ${today.toLocaleDateString(locale, { month: 'long' })} ${today.getDate()}, ${today.getFullYear()}`;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.get("dashboard")
        .then(res => {
            return res.data;  
        })
        .then(data => {               
            setName(data.data.name);
            setNoOfProducts(data.data.products)
            setNoOfCategory(data.data.categories)
            setNoOfSubCatefory(data.data.subcategories)
            setLoading(false)
        })
        .catch(error => {
        console.log("error", error);
    })
       
    }, []);
    
    return (<><main>
        {loading ? <>
            <div className='my-auto items-center overscroll-none'> 
                <SpinnerCircularFixed className='min-h-screen m-auto'/>
            </div> 
        </>
        : <>
        <CenterContainer>
            <div className="bg-banner bg-cover bg-bottom bg-no-repeat w-full h-48">
                <GridContainer bg='none' height='h-60' padding='none'> 
                <div></div>
                <div className='col-span-2 my-auto pt-8 mr-16'>
                    <div className="w-full">  
                        <Span margin="top" type="white" size='3xl' padding='none'>Hello, {name} </Span>
                    </div> 
                    <div className='flex justify-between'>
                        <Span type='white' margin='top'> How are you today? </Span>
                        <Span type='white' margin='top'>{date}</Span> 
                    </div>
                </div>
                </GridContainer>
            </div>

            <GridContainer padding="top" >
                <CardContainer bg="white">
                    <H3 type="darkgrey">Total Product</H3>
                    <div className="flex justify-between"> 
                        <Span type="green" size="4xl">{totalProducts}</Span>
                        <img src={product} alt="" className="dashboard-img"/>
                    </div>
                </CardContainer>
                <CardContainer bg="white">
                    <H3 type="darkgrey">Total Categories</H3> 
                    <div className="flex justify-between"> 
                        <Span type="green" size="4xl">{totalCategory}</Span>
                        <img src={categories} alt="" className="dashboard-img"/>
                    </div>
                </CardContainer>
                <CardContainer bg="white">
                    <H3 type="darkgrey">Total Sub Categories</H3>
                    <div className="flex justify-between"> 
                        <Span type="green" size="4xl">{totalSubcategory}</Span>
                        <img src={subCategories} alt="" className="dashboard-img"/>
                    </div>
                </CardContainer> 
            </GridContainer>
        </CenterContainer>
        </>}
        </main>
    </>)
}