import { useState, useEffect } from "react"; 
import { CenterContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading"  
import { GridContainer, Container } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button' 
import { Select } from '../../components/common/Select'
import { useHistory, Link } from 'react-router-dom'
   
import * as Yup from 'yup'
import { useFormik } from 'formik'
import api from '../../network/api';
import UpdateModal from './Modal/UpdateModal'
 
const validationSchema = Yup.object({  
    title: Yup.string().required('Required!'), 
    description: Yup.string(),
    price: Yup.string().required('Required!'), 
    unit: Yup.string().required('Required!'),
    category: Yup.string().required('Required!'), 
    subcategory: Yup.string().required('Required!'),
    merchant: Yup.string().required('Required!')
}) 

export default ({id, ...props}:any) => {   

    const [initialValues, setData] = useState({
        title: '',
        description: '',
        price: '',
        quantity: '',
        unit: '',
        category: '',
        subcategory: '', 
        merchant: '',
    } as any)

    
    // Formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues, 
        onSubmit: () => {},
        validationSchema
    })

    // Upload image 
    const [fileEdit,setFileEdit] = useState({} as any)
    const [previewEdit,setPreviewEdit] = useState("")  
      
    const [error, setError]= useState(null);

    const [cat, setCategory] = useState([])
    const [merch, setMerchant] = useState([])
    const [subcat, setSubcategory] = useState([]) 
       
    useEffect(() => { 

        api.get(`get-category`)
        .then(res => {
             setCategory(res.data.data.category ) 
        })
        .catch(error => {
                setError(error.message);
        })

        api.get(`get-subcategory`)
        .then(res => {
            setSubcategory(res.data.data.subcategory ) 
        })
        .catch(error => {
                setError(error.message);
        })

        api.get(`get-merchant`)
        .then(res => {
            setMerchant(res.data.data.merchant ) 
        })
        .catch(error => {
                setError(error.message);
        })

        api.get(`get-one-product/${id}`)
        .then(res => {
            setData({
                title: res.data.data[0].title,
                description: res.data.data[0].description, 
                price: res.data.data[0].price,
                unit: res.data.data[0].unit,
                category: res.data.data[0].category_name,
                subcategory: res.data.data[0].subcategory_name,
                merchant: res.data.data[0].merchant
            }) 
            setPreviewEdit(res.data.data[0].image)
            setFileEdit(res.data.data[0].image)
        })
        .catch(error => {
                setError(error.message);
            }) 
    }, [])
    

    const handleProductEdit= (e) => {
        e.preventDefault(); 
        const selectedFileEdit = e.target.files[0]
        setFileEdit(selectedFileEdit)
        const filePreviewEdit = URL.createObjectURL(selectedFileEdit)
        setPreviewEdit(filePreviewEdit)
    } 

    
    const history = useHistory(); 
    const onSubmit = (e) => {
        e.preventDefault();
 
        let formData = new FormData();
        formData.append('title',  formik.values.title)
        formData.append('description',  formik.values.description)
        formData.append('price', formik.values.price)
        formData.append('unit', formik.values.unit)
        formData.append('category', formik.values.category)
        formData.append('subcategory', formik.values.subcategory)
        formData.append('merchant', formik.values.merchant)
        formData.append('image', fileEdit)
        api.put(`update-product/${id}`, formData) 
        .then(res => { 
            history.push("/list");
            window.location.reload();
        }).catch(error => {
            setError(error.message);
        }) 
    } 

    const [updateModal, setUpdateModal] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault(); 
        setUpdateModal(true);
    } 

    return (<>
    <form>
    <Container bg='white' padding="x">
        <GridContainer bg='white' padding='top-sm' grid='two'>
            <div>
                
                <Span>Title</Span>
                <Input type='text' radius='rounded' id='title'
                    {...formik.getFieldProps('title')}/>
                {formik.touched.title && formik.errors.title? <div className="error">{formik.errors.title}</div> : null}
            </div>
             
            <div> 
                <Span>Description</Span>
                <Input type='text' radius='rounded' id='description'
                    {...formik.getFieldProps('description')}/>
                {formik.touched.description && formik.errors.description? <div className="error">{formik.errors.description}</div> : null}
            </div>
        
            <div> 
                <Span>Price</Span>
                <Input type='number' radius='rounded' id='price'
                    {...formik.getFieldProps('price')}/>
                {formik.touched.price && formik.errors.price? <div className="error">{formik.errors.price}</div> : null}
            </div>  

            <div> 
                <Span>Unit</Span>
                <Input type='text' radius='rounded' id='unit'
                    {...formik.getFieldProps('unit')}/>
                {formik.touched.unit && formik.errors.unit? <div className="error">{formik.errors.unit}</div> : null}
            </div>  

            </GridContainer>

            <GridContainer bg='white' padding='top-sm'>
            <div>
                <Span>Main Category</Span>  
                <Select  {...formik.getFieldProps('category')} >
                {/* <option selected disabled hidden>{initialValues.category}</option> */}
                    <option value="" selected disabled hidden>-- Select --</option>
                    {cat.map((category:any) => { 
                    return ( <option>{category.name}</option>);
                    })}
                </Select>
                {formik.touched.category && formik.errors.category? <div className="error">{formik.errors.category}</div> : null}
            </div>

            <div> 
                <Span>Sub Category</Span>
                <Select {...formik.getFieldProps('subcategory')}>
                {/* <option selected disabled hidden>{initialValues.subcategory}</option> */}
                    <option value="" selected disabled hidden>-- Select --</option>
                    {subcat.map((subcategory:any) => { 
                    return ( <option>{subcategory.name}</option>);
                    })}
                </Select>
                {formik.touched.subcategory && formik.errors.subcategory? <div className="error">{formik.errors.subcategory}</div> : null}
            </div>

             <div>
                <Span>Merchant</Span>  
                <Select id='merchant' {...formik.getFieldProps('merchant')}>
                    <option value="" selected disabled hidden>-- Select --</option>
                    {merch.map((merchant:any) => { 
                    return ( <option>{merchant.name}</option>);
                    })}
                </Select>
                {formik.touched.merchant && formik.errors.merchant? <div className="error">{formik.errors.merchant}</div> : null}
            </div>

        </GridContainer>

        <GridContainer bg='white' padding='top-sm' grid="four">
            <div>
                <Span>Product Image</Span>

                {fileEdit && <img src={previewEdit} alt='' className='product'/>}  
                
                <input type="file" name="product" id="upload_product" onChange={(e) => handleProductEdit(e)} accept="image/*"/>
                <label htmlFor="upload_product" className="cursor-pointer"> 
                    <Span type='lightgreen' weight="medium">+ Change image</Span>
                </label>  
            </div> 
        </GridContainer>

        <div className='text-right'>
            <Button width='none' padding='px' type='lightgreen' margin='xy' radius='rounded' 
                onClick={handleSubmit}
                // disabled={!(formik.dirty && formik.isValid)}
            >
                Update
            </Button>
            <Link to="/list">
                <Button width='none' padding='px' type='grey' radius='rounded'>Cancel</Button>
            </Link>
        </div>
    </Container>
    </form>

    {updateModal &&
        <UpdateModal setUpdateModal={setUpdateModal} onSubmit={onSubmit}/>
    }
    </>);
}