import React from 'react'
import { Link } from 'react-router-dom'

import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { RootState, Dispatch } from "../store";

import { useHistory } from "react-router-dom";
export default () => {
    const history = useHistory();
    
    // const token: any = useSelector(
    //     (state: RootState) => state.User.token
    // );
    // const dispatch = useDispatch<Dispatch>();
    
    history.push("/");

    // dispatch.User.logout();

    return (
        <div>
        </div>
    )
}
