import React from 'react'
import LoginPage from '../containers/LoginPage'  
 
const Login = () => {
    return ( 
        <> 
            <LoginPage/>  
        </>
    )
}

export default Login
