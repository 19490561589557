/* eslint-disable import/no-anonymous-default-export */
import { useMemo, useEffect, useState } from "react"; 
import { CenterContainer, SubContainer, GridContainer } from "../../components/common/Container"; 
import { H1, H2 } from "../../components/common/Heading" 
import { Button } from '../../components/common/Button';   
import { Span } from "../../components/common/Span"  
import { Link, useHistory } from 'react-router-dom'
import  { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table' 
import { BiSortDown, BiSortUp } from 'react-icons/bi'

import { DateRangeFilter } from './Table/DateRangeFilter'
import { GlobalFilter } from './Table/GlobalFilter'
import { COLUMNS } from './Table/columnProcess' 
import api from "../../network/api";
import Loading from '../../components/Loading'
 
export default () => {  
    // Table
    const columns = useMemo(() => COLUMNS, [])
    // const data = useMemo(() => processdata, []) 
    const [data, setTableData] = useState([])
    const [error, setError]= useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        api.get(`get-order-list`) 
        .then(res => {
            setTableData(res.data.data.order.filter((x: { status: string }) =>
        x.status === "cancelled" ||  x.status === "delivered"  
        ))
           setLoading(false);
        })
      .catch(error => {
                setError(error.message);
            })
      }, [])
    
    const tableInstance = useTable({ columns, data },
        useFilters, useGlobalFilter, useSortBy, usePagination
    ) 

    const { 
        getTableProps, 
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setPageSize,
        state, 
        setHiddenColumns,
        prepareRow,
        preGlobalFilteredRows, 
        setGlobalFilter 
    } = tableInstance

    const { pageIndex, pageSize, globalFilter } = state

    useEffect(() => {
        console.log(columns.filter(column => !column.isVisible));
        setHiddenColumns(
          columns.filter(column => !column.isVisible).map(column => column.accessor)
        );
      }, [setHiddenColumns, columns]); 
 
      
    const history = useHistory();
    const handleRowClick =(e:any, row:any)=>{
        history.push(`order/details/${row.original.id}`); 

    }

    return (<><main>
        {loading ? <>
            <Loading />
        </>
        : <>
        <CenterContainer padding="header"> 
        <H1 weight='medium'>Mommymili</H1> 

        <GridContainer padding='top-sm'>  
                <div className='col-span-2'>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />  
                </div>
                {/* <div><DateRangeFilter /></div> */}
                <div>
                    {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                        column.Filter ? (
                        <div key={column.id}> 
                            {column.render("Filter")}
                        </div>
                        ) : null
                    )
                    )} 
                </div> 
        </GridContainer>

        <Link to='/order'>
            <Button padding='px-1' width='none' type='none' margin='top'> 
                Ongoing Order
            </Button>
        </Link>
        <Link to='/process'>
            <Button padding='px-1' width='none' type='none' margin='top' border='border-b' weight='semibold'>
                Processed Order
            </Button>
        </Link>

        <SubContainer flex='flex' bg='white' border='grey' borderWidth='bottom' padding='normal'> 
            <H2 weight="semibold">Order Management</H2>
        </SubContainer> 
                
                
            <div className='table-md-inv'>
                <table {...getTableProps()} border='0' cellspacing='0' cellpadding='0'>
                <thead>
                    {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className='th-md'> 
                            <div className='flex justify-center'>
                                {column.render('Header')} 
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? <BiSortDown className='my-auto text-lg mx-2'/>
                                        : <BiSortUp className='my-auto text-lg mx-2'/>
                                    : ''} 
                            </div>
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                
                <tbody {...getTableBodyProps()}> 
                    {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr onClick={(e) => handleRowClick(e,row)} {...row.getRowProps()} className="rounded-lg cursor-pointer">
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()} className='td-md text-center'>{cell.render('Cell')}</td>;
                            })}
                        </tr>
                    );
                    })}
                </tbody>
            </table> 
            <div className='flex justify-between'>
                <div className='my-4'>
                    <select
                        className='px-2 rounded border border-getAllGrey-200 page'
                        value={pageSize} 
                        onChange={(e) => setPageSize(Number(e.target.value))}>
                            {[10,25,50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize} rows
                                </option>
                            ))}
                    </select>
                </div>

                <div>
                    <Button 
                        width='none' padding='px' type='white' radius='left' color='black' border='darkgrey'
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        Previous
                    </Button>
                    <Span bg='green' margin='auto' padding='py-2.5' type='white' border='tb-grey'>  
                        {pageIndex + 1} 
                    </Span>
                    <Button 
                        width='none' padding='px' type='white' radius='right' color='black' border='darkgrey'
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        Next
                    </Button>
                </div> 
            </div>
            </div> 
        </CenterContainer> 
        </>}

        </main>
    </>)
}