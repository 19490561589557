import { useMemo, useState, useEffect } from 'react'  
import { CenterContainer, SubContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading" 
import { Span } from "../../components/common/Span"  
  
import MarketTab from './MarketTab'
import Table from '../../components/Table' 
  
import { COLUMNS } from './Table/column'
import api from '../../network/api' 
import Loading from '../../components/Loading'

export default () => {   

    const [loading, setLoading] = useState(true);
    const [market, setMarket] = useState(false);
    const toggleMarket = (e:any) => {
        e.preventDefault();
        setMarket(!market);
    }; 

    const columns = useMemo(() => COLUMNS, [])
    const [data, setCategoryData] = useState([])
    
    useEffect(() => {
      api.get("get-market-list")
            .then(res => {
                setCategoryData( res.data.data.data) 
                setLoading(false);
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    return (<><main>
        {loading ? <>
            <Loading />
        </>:<> 
            <CenterContainer padding="header">
            <H1 weight='medium'>Market</H1> 

            <div className="toggle-container" onClick={toggleMarket}>
                <i className="material-icons my-auto">
                    {market  ? 'expand_more' : 'chevron_right' }
                </i>   
                <Span weight="semibold">New Market</Span>
            </div>
             
            {market ? (
                <>
                    <MarketTab/>
                </>
            ) : null}  

            <SubContainer flex='flex' bg='white' border='grey' margin='top' borderWidth='bottom'  padding='normal'> 
                <Span weight="semibold">Market List</Span>
            </SubContainer> 

            <Table columns={columns} data={data}/>     

        </CenterContainer>
        </>}
        </main>
    </>)
}