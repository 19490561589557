/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useMemo, useState } from 'react' 
import { CenterContainer, SubContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading" 
import { Span } from "../../components/common/Span"  
   
import ListTab from './ListTab'

import { COLUMNS } from './Table/column' 
import Table from '../../components/Table'  
import api from '../../network/api' 

import Loading from '../../components/Loading'

export default () => {   

    const [loading, setLoading] = useState(true);

    const [list, setList] = useState(false);
    const toggleList = (e:any) => {
        e.preventDefault();
        setList(!list);
    };

    const columns = useMemo(() => COLUMNS, [])
    const [data, setProductData] = useState([])
    
    useEffect(() => {
      api.get("get-product-list")
            .then(res => { 
                setProductData(res.data.data.data);
                setLoading(false) 
            })
            .catch(error => {
                console.log(error)
            })
    }, [])


    return (<><main>
        {loading ? <>
            <Loading />
        </>:<> 
        <CenterContainer padding="header">
            <H1 weight='medium'>Product</H1> 

            {/* New Category */}
            <div className="toggle-container" onClick={toggleList}>
                <i className="material-icons my-auto">
                    {list  ? 'expand_more' : 'chevron_right' }
                </i>   
                <Span weight="semibold">New Product</Span>
            </div>
             
            {list ? (
                <>
                    <ListTab setLoading={setLoading}/>
                </>
            ) : null} 

            {/* Product List */}
            <SubContainer flex='flex' bg='white' border='grey' margin='top' borderWidth='bottom' padding='normal'> 
                <Span weight="semibold">Product List</Span>
            </SubContainer> 
                 
                <Table columns={columns} data={data}/>   
        </CenterContainer> 
        </>}
        </main>
    </>)
}