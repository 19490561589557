import { useMemo, useState, useEffect } from 'react'  
import { CenterContainer, SubContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading" 
import { Span } from "../../components/common/Span"  
  
import MerchantTab from './MerchantTab'
import Table from '../../components/Table'  
import { COLUMNS } from './Table/column'
import api from '../../network/api' 
import Loading from '../../components/Loading'

export default () => {  

    const [loading, setLoading] = useState(true);
    const [merchant, setMerchant] = useState(false);
    const toggleMerchant = (e:any) => {
        e.preventDefault();
        setMerchant(!merchant);
    }; 

    const columns = useMemo(() => COLUMNS, [])
    const [data, setCategoryData] = useState([])
    
    useEffect(() => {
      api.get("get-merchant-list")
            .then(res => {
                setCategoryData(res.data.data.data);
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    return (<><main>
        {loading ? <>
            <Loading />
        </>:<> 
        <CenterContainer padding="header">
            <H1 weight='medium'>Merchant</H1> 

            <div className="toggle-container" onClick={toggleMerchant}>
                <i className="material-icons my-auto">
                    {merchant  ? 'expand_more' : 'chevron_right' }
                </i>   
                <Span weight="semibold">New Merchant</Span>
            </div>
             
            {merchant ? (
                <>
                    <MerchantTab/>
                </>
            ) : null}  

            <SubContainer flex='flex' bg='white' border='grey' margin='top' borderWidth='bottom'  padding='normal'> 
                <Span weight="semibold">Merchant List</Span>
            </SubContainer> 
                
            <Table columns={columns} data={data}/>   

        </CenterContainer> 
        </>}
        </main>
    </>)
}