import React from "react"; 
import { CenterContainer, SubContainer } from "../components/common/Container";
import { Span } from "../components/common/Span";
import { Button } from "../components/common/Button";
 
export default () => {   
   

  return (<>
    <CenterContainer align="end" flex="flex" bg="broken">
        <SubContainer textalign='right' bg='none'> 
          <div className='mb-14'> 
            <Span size='4xl' type='white'>Sorry, the page you visited <br /> does not exist.</Span> 
          </div> 
          <div>
            <Button width='none' padding='px' type='brokengreen' radius='lg' onClick={() => window.location.reload()}>Refresh</Button> 
          </div>
        </SubContainer>
        
    </CenterContainer>
  </>)
}