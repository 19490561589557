import React from 'react'
import { MainContent } from '../components/common/Container'
import DashboardPage from '../containers/DashboardPage'  
import Navbar from '../containers/Navbar'
import Sidebar from '../containers/Sidebar'
 
const Dashboard = () => {
    return ( 
        <> 
            <Sidebar/>
            <MainContent>
                <Navbar /> 
                <DashboardPage/>
            </MainContent>
        </>
    )
}

export default Dashboard
