/* eslint-disable jsx-a11y/alt-text */
import { Link } from 'react-router-dom'
import { ColumnFilter } from "../../../components/ColumnFilter";
import { GoPrimitiveDot } from "react-icons/go";
import { height } from '@mui/system';  
import DeleteProduct from '../DeleteProd';
import defaultimage from '../../../images/defaultimage.png';

export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter,
        disableFilters: true, 
        isVisible: false
    },
    {
        accessor: 'image',
        Header: 'Image',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
        Cell: (props) => {
            return (
              <><img style={{maxHeight: '75px'}} src={props.row.original.image ? props.row.original.image : defaultimage }/></>
            ); }
    },
    {
        accessor: 'title',
        Header: 'Title',
        Filter: ColumnFilter, 
        isVisible: true, 
    }, 
    {
        accessor: 'description',
        Header: 'Description',
        Filter: ColumnFilter, 
        isVisible: true
    },
    {
        accessor: 'merchant',
        Header: 'Merchant',
        Filter: ColumnFilter, 
        isVisible: true   
    }, 
    {
        accessor: 'price',
        Header: 'Price',
        type: "currency",
        Filter: ColumnFilter,
        isVisible: true,
        Cell: props => <div className="m-auto"> 
            <span>₱ {parseFloat(props.row.original.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            </div>        
    }, 
    {
        accessor: 'action',
        Header: 'Action',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => <div className="flex justify-center"> 
                {/* <Link to={`list/disable/${data.row.original.id}`} className="disable">Disabled</Link> */}
                <Link to={`list/edit/${data.row.original.id}`} className="edit">Edit</Link>
                {/* <Link to={`list/delete/${data.row.original.id}`} className="delete">Delete</Link> */}
                <DeleteProduct id={data.row.original.id}/>
            </div> 
    }
]