import * as React from 'react';
import classnames from 'classnames'

type Props = {
  children: React.ReactNode,
  weight?: 'bold' | 'semibold' | 'medium', 
  type?:  'green' | 'grey' |  'darkgrey' | 'white',
  margin?: 'top' | 'mt-8' | 'x-auto',
  align?:  'center',
  padding?: 'x'
}

let getStyle = (type: Props['type']) => {
  switch (type) { 
    case 'white':
      return 'text-white';    
    case 'darkgrey':
      return 'text-getAllGrey-400';
    case 'grey':
      return 'text-getAllGrey-100';
    case 'green':
      return 'text-getAllGreen-200';
    default:
      return 'text-getAllBlack-100';
  }
}

let getWeight = (weight: Props['weight']) => {
  switch (weight) {
    case 'bold':
      return 'font-bold';
    case 'semibold':
      return 'font-semibold';
    case 'medium':
      return 'font-medium';
    default:
      return 'font-normal';
  }
}
 
let getAlign = (align: Props['align']) => {
  switch (align) {
    case 'center':
      return 'text-center';
    default:
      return 'text-left';
  }
}

let getMargin = (margin: Props['margin']) => {
  switch (margin) {
    case 'x-auto':
      return 'mx-auto my-2';
    case 'top':
      return 'my-auto';
    case 'mt-8':
      return 'mt-8';
    default:
      return 'my-2';
  }
}

let getPadding= (padding: Props['padding']) => {
  switch (padding) {   
    case 'x':
      return 'px-2'; 
    default:
      return '';
  }
} 

export const H1 = ({type, weight, align, margin, padding, children }: Props) => {
  return  <h1 className={classnames('text-2xl', getStyle(type), getWeight(weight), getAlign(align),
          getMargin(margin), getPadding(padding)
            )} >{children}
          </h1>
}
 
export const H2 = ({type, weight, align, margin, padding, children }: Props) => {
    return  <h2 className={classnames('text-xl', getStyle(type), getWeight(weight), getAlign(align),
            getMargin(margin), getPadding(padding)
              )} >{children}
            </h2>
}

export const H3 = ({type, weight, align, margin, padding, children }: Props) => {
    return  <h3 className={classnames('text-lg',getStyle(type), getWeight(weight), getAlign(align),
            getMargin(margin), getPadding(padding)
              )} >{children}
            </h3>
  }