import { useState, useEffect } from "react"; 
import { CenterContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading"  
import { GridContainer, InputContainer, Container, SubContainer } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button' 
import { Select } from '../../components/common/Select'
import { useHistory, Link } from 'react-router-dom'
   
import * as Yup from 'yup'
import { useFormik } from 'formik'
import api from '../../network/api';
import UpdateModal from './Modal/UpdateModal'

import Switch from "react-switch";
 
const validationSchema = Yup.object({ 
    name: Yup.string().required('Required!'),
    market: Yup.string().required('Required!')
}) 

export default ({id, ...props}:any) => { 
    
    // Upload image 
    const [coverMerchantEdit,setCoverMerchantEdit] = useState("")
    const [previewCoverMerchantEdit,setPreviewCoverMerchantEdit] = useState("") 
    // const [imgCoverMarket,setImgCoverMarket] = useState(true);
    const [fileCoverMerchantEdit, setFileCoverMerchantEdit] = useState('Choose File'); 

    const [bannerMerchantEdit,setBannerMerchantEdit] = useState("")
    const [previewBannerMerchantEdit,setPreviewBannerMerchantEdit] = useState("")
    // const [imgBannerMarket,setImgBannerMarket] = useState(true); 
    const [fileBannerMerchantEdit, setFileBannerMerchantEdit] = useState('Choose File');

    const handleCoverMerchantEdit = (e) => {
        e.preventDefault(); 
        setFileCoverMerchantEdit(e.target.files[0].name);
        const selectedCoverMerchantEdit = e.target.files[0]
        setCoverMerchantEdit(selectedCoverMerchantEdit)
        const coverMerchantEditPreview = URL.createObjectURL(selectedCoverMerchantEdit)
        setPreviewCoverMerchantEdit(coverMerchantEditPreview)
        // setImgCoverMarket(false)
    } 

    const handleBannerMerchantEdit = (e) => {
        e.preventDefault(); 
        setFileBannerMerchantEdit(e.target.files[0].name);
        const selectedBannerMerchantEdit = e.target.files[0]
        setBannerMerchantEdit(selectedBannerMerchantEdit)
        const bannerMerchantEditPreview = URL.createObjectURL(selectedBannerMerchantEdit)
        setPreviewBannerMerchantEdit(bannerMerchantEditPreview)
        // setImgBannerMarket(false)
    } 

    const [initialValues, setData] = useState({
        name: '',
        market: '',
        is_featured: ''
    })

    // Formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues, 
        onSubmit: () => {},
        validationSchema
    })


    const history = useHistory(); 

    // Submit
    const onSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('merchant',  formik.values.name)
        formData.append('market',  formik.values.market)
        formData.append('featured',  featured)
        formData.append('banner_image', bannerMerchantEdit)
        formData.append('thumbnail_image', coverMerchantEdit)
        api.put(`update-merchant/${id}`,formData)
        .then(res => { 
            history.push("/merchant");
            window.location.reload(); 
        }).catch(error => {
            setError(error.message);
        }) 
    }
 

    // Get Data 
    const [error, setError]= useState(null);  
    const [mark, setMarket] = useState([])
      useEffect(() => { 
        const merchant = api.get(`get-one-merchant/${id}`)
        const market = api.get("get-market") 
    
        api.all([ merchant, market]).then(
            api.spread((...allData) => {
                const merchant = allData[0].data.data
                let {banner_image, thumbnail_image } = merchant
                setData({
                    name: allData[0].data.data.name,
                    market: allData[0].data.data.market,
                    is_featured:  allData[0].data.data.is_featured
                });

                setChecked(allData[0].data.data.is_featured === 1)
                setPreviewBannerMerchantEdit(banner_image)
                setBannerMerchantEdit(banner_image)
                setPreviewCoverMerchantEdit(thumbnail_image)
                setCoverMerchantEdit(thumbnail_image)

                const mark = allData[1].data.data.market  
 
                setMarket(mark) 
            })
        )
    
          }, [])


    const [updateModal, setUpdateModal] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault(); 
        setUpdateModal(true);
    }

    const [checked, setChecked]: any = useState() 

    const [featured, setFeatured] : any = useState(0) 
    useEffect(() => {
        if(checked){ 
            setFeatured(1)
        }else{ 
            setFeatured(0)
        }
    }, [checked])

    const handleChange =(checked)=> {
        setChecked(checked);
      }

          
    return (<><main>
        <CenterContainer padding="header">
            <H1 weight='medium'>Edit Merchant</H1> 

            <div className="toggle-container"> 
                <Span weight="semibold">Merchant Details</Span>
            </div> 

            <Container bg='white' padding="x">
        <GridContainer bg='white' padding='top-sm' grid='two'>
            <div>
                <Span>Merchant</Span>
                <Input type='text' radius='rounded' id='name'
                    {...formik.getFieldProps('name')}/>
                {formik.touched.name && formik.errors.name? <div className="error">{formik.errors.name}</div> : null}
            </div> 

            <div>
                <Span>Market</Span>
                <Select id='market' {...formik.getFieldProps('market')}>
                    <option value="" selected disabled hidden>-- Select --</option>
                    {mark.map((market:any) => { 
                    return ( <option>{market.name}</option>);
                    })}
                </Select> 
                {formik.touched.market && formik.errors.market? <div className="error">{formik.errors.market}</div> : null}
            </div> 
          
        </GridContainer>
        <GridContainer bg='white' padding='none' grid='two'>
            <div>
                <Span>Cover Image</Span>  
                <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'>
                    <Input type='text' border='none' radius='rounded' id='thumbnail_image' value={fileCoverMerchantEdit} disabled/>
                    <span className="browse">
                        <input type="file" name="cover" id="upload_cover" onChange={(e) => handleCoverMerchantEdit(e)} accept="image/*"/>
                        <label htmlFor="upload_cover" className="cursor-pointer">Browse</label> 
                    </span> 
                </InputContainer>
                
                {/* {imgCoverMarket && <img src={img} alt='' className='cover'/>} */}
                {coverMerchantEdit && <img src={previewCoverMerchantEdit} alt='' className='cover'/>}   
            </div>
            <div>
                <Span>Banner Image</Span>  
                <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'>
                    <Input type='text' border='none' radius='rounded' id='banner_image' value={fileBannerMerchantEdit} disabled/>
                    <span className="browse">
                        <input type="file" name="banner" id="upload_banner" onChange={(e) => handleBannerMerchantEdit(e)} accept="image/*"/>
                        <label htmlFor="upload_banner" className="cursor-pointer">Browse</label> 
                    </span>
                </InputContainer>

                {/* {imgBannerMarket && <img src={img} alt='' className='banner'/>} */}
                {bannerMerchantEdit && <img src={previewBannerMerchantEdit} alt='' className='banner'/>}   
            </div>
        </GridContainer>

        <SubContainer flex='flex' bg='white' padding='top-sm'> 
            <Span> Featured</Span> 
            <div className='mx-6'>  
                    <Switch 
                        onChange={handleChange} 
                        checked={checked}
                        checkedIcon={false}
                        uncheckedIcon={false} 
                        onColor='#9fbf15' 
                    /> 
            </div>
        </SubContainer>  

        <div className='text-right'>
                <Button 
                    width='none' padding='px' type='lightgreen' margin='xy' radius='rounded'
                    onClick={handleSubmit}  
                    // disabled={!(formik.dirty && formik.isValid)}
                >
                    Update
                </Button>
                <Link  to="/merchant">
                    <Button width='none' padding='px' type='grey' radius='rounded'>Cancel</Button>
                </Link>
            </div>
    </Container>
 

        </CenterContainer> 

        {updateModal &&
            <UpdateModal setUpdateModal={setUpdateModal} onSubmit={onSubmit}/>
        } 
        </main>
    </>)
}