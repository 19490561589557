import { StatusFilter } from './StatusFilter' 
import { Link } from 'react-router-dom'
import classnames from 'classnames';
import { format } from 'date-fns'
import { ORDER_STATUS } from '../../../constants/order';   

export const COLUMNS = [
    {
    accessor: 'id',
    Header: 'ID', 
    disableFilters: true,
    isVisible: false,
    },
    {
        accessor: 'order_number',
        Header: 'Order ID', 
        disableFilters: true,
        isVisible: true,
    },
    {
        accessor: 'status',
        Header: 'Status', 
        disableFilters: true,
        Filter: StatusFilter,
        filter: 'includes', 
        isVisible: true,
        Cell: props =>  
            <span
              className={classnames( 
                props.row.original.status === 'new_order' ? "bg-getAllGrey-104 px-11 status" : null,
                props.row.original.status === 'processing' ? "bg-getAllGreen-200 px-10 status" : null,
                props.row.original.status === 'for_delivery' ? "bg-getAllViolet-100 px-10 status" : null, 
              )}
            >
              {ORDER_STATUS[props.row.original.status]}
            </span> 
    }, 
    {
      accessor: 'customer_name',
      Header: 'Customer', 
      isVisible: true,        
  },
    {
        accessor: 'order_place',
        Header: 'Order Place At',   
        isVisible: true, 
        Cell: ({value}) => { return format(new Date(value), 'MMM dd, yyyy hh:mm a')} 
    },
    {
        accessor: 'market_name',
        Header: 'Market', 
        isVisible: true,    
    },
    {
        accessor: 'total_amount',
        Header: 'Total Amount',
        type: "currency", 
        isVisible: true,
        Cell: props => <div className="m-auto"> 
            <span>₱ {parseFloat(props.row.original.total_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            </div>        
    }
]