import { GridContainer, Container } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button' 
import { Select } from '../../components/common/Select'

import * as Yup from 'yup'
import { useFormik } from 'formik'
  
const initialValues = {
    lastname: '',
    firstname: '',
    email: '',
    role: '',
    mobile: ''
}

const onSubmit = values => {
  console.log('Form data', values)
}

const validationSchema = Yup.object({  
    lastname: Yup.string().required('Required!'), 
    firstname: Yup.string().required('Required!'),
    email: Yup.string().email('Invalid email format').required('Required!'),
    role: Yup.string().required('Required!'),
    mobile: Yup.string().required('Required!')
}) 

export default () => {  

    // Formik
    const formik = useFormik({
        initialValues, 
        onSubmit,
        validationSchema
    })

    return (<>
    <Container bg='white' padding="x"> 
        <GridContainer bg='white' padding='top-sm' grid='two'>   
            <div>
                <Span>Last Name</Span>
                <Input type='text' radius='rounded' placeholder='Last Name' 
                id='lastname' {...formik.getFieldProps('lastname')}/>
                {formik.touched.lastname && formik.errors.lastname? <div className="error">{formik.errors.lastname}</div> : null}
            </div> 
            <div>
                <Span>First Name</Span>
                <Input type='text' radius='rounded' placeholder='First Name' 
                id='firstname' {...formik.getFieldProps('firstname')}/>
                {formik.touched.firstname && formik.errors.firstname? <div className="error">{formik.errors.firstname}</div> : null}
            </div>
        </GridContainer>

         <GridContainer bg='white' padding='none'>   
         <div> 
            <Span>Email</Span>
                <Input type='email' radius='rounded' placeholder='Email'
                id='email' {...formik.getFieldProps('email')}/>
                {formik.touched.email && formik.errors.email? <div className="error">{formik.errors.email}</div> : null}
            </div>
            <div> 
            <Span>Role</Span>
            <Select id='role' {...formik.getFieldProps('role')}>
                    <option value="" selected disabled hidden>Role</option>
                    <option value="Admin">Admin</option>
                    <option value="CSR">CSR</option> 
                </Select>
                {formik.touched.role && formik.errors.role? <div className="error">{formik.errors.role}</div> : null}
            </div>
            <div>
            <Span>Mobile Number</Span>
                <Input type='number' radius='rounded' placeholder='Mobile Number' 
                id='mobile' {...formik.getFieldProps('mobile')}/>
                {formik.touched.mobile && formik.errors.mobile? <div className="error">{formik.errors.mobile}</div> : null}
            </div> 
        </GridContainer> 

        <div className='text-right'>
            <Button width='none' padding='px' type='lightgreen' margin='xy' radius='rounded'>Add User</Button>
            <Button width='none' padding='px' type='grey' radius='rounded'>Cancel</Button>
        </div>
    </Container>
    </>);
}