import { Link } from 'react-router-dom'
import { ColumnFilter } from "../../../components/ColumnFilter";   
import DeleteMerchant from '../DeleteMerchant';

export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
    },
    {
        accessor: 'name', //merchant
        Header: 'Merchant',
        Filter: ColumnFilter,
        isVisible: true,        
    },
    {
        accessor: 'is_featured',
        Header: 'Featured',
        Filter: ColumnFilter,
        Cell: (props) => {
            return (<>
                <span>{props.row.original.is_featured ? "Yes" : "No"}</span>
            </>); 
        },
        isVisible: true,        
    },
    {
        accessor: 'market',
        Header: 'Market',
        Filter: ColumnFilter,
        isVisible: true,        
    },
    {
        accessor: 'thumbnail_image',
        Header: 'Cover',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
        Cell: (props) => {
            return (<>
                <img className='column-image'
                     src={props.row.original.thumbnail_image}/>
            </>); 
        }
    },
    {
        accessor: 'banner_image',
        Header: 'Banner',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
        Cell: (props) => {
            return (<>
                <img className='column-image'
                     src={props.row.original.banner_image}/>
            </>); 
        }
    },
    {
        accessor: 'action',
        Header: 'Action',
        Filter: ColumnFilter,
        isVisible: true,
        disableFilters: true,
        Cell: data => <div className="flex justify-center">
                <Link to={`merchant/edit/${data.row.original.id}`} className="edit">Edit</Link>
                {/* <Link to={`market/delete/${data.row.original.id}`} className="delete">Delete</Link> */}
                <DeleteMerchant id={data.row.original.id}/>
            </div> 
    }
]