import { useState } from 'react'  
import { GridContainer, InputContainer, Container } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button'

import img from '../../images/img.jpg'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import api from '../../network/api';
// import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom"; 
  
const initialValues = {
  market: '', 
} 

const validationSchema = Yup.object({ 
  market: Yup.string().required('Required!')
}) 
  
export default () => { 

    // Upload image 
    const [bannerMarket,setBannerMarket] = useState("")
    const [previewBannerMarket,setPreviewBannerMarket] = useState("")  
    const [imgMarket,setImgMarket] = useState(true)
    const [fileMarket, setFileMarket] = useState('Choose File');

    const handleBannerMarket = (e) => {
        e.preventDefault(); 
        setFileMarket(e.target.files[0].name);
        const selectedBannerMarket = e.target.files[0]
        setBannerMarket(selectedBannerMarket)
        const bannerMarketPreview = URL.createObjectURL(selectedBannerMarket)
        setPreviewBannerMarket(bannerMarketPreview)
        setImgMarket(false)
    } 

    // Formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,  
        onSubmit: () => {},
        validationSchema
    })

    
    const [error, setError]= useState(null);
    const history = useHistory();
    const onSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('market',  formik.values.market) 
        formData.append('banner_image', bannerMarket)
        api.post('save-market',formData).then(res => { 
            history.push("/market");
            window.location.reload(); 
        }).catch(error => {
            setError(error.message);
        })
    }
 
    return (<>
    <Container bg='white' padding="x">
        <GridContainer bg='white' padding='top-sm' grid="two">
            <div>
                <Span>Name</Span>
                <Input type='text' radius='rounded' placeholder='Enter mommyMili market'
                    id='name'
                    {...formik.getFieldProps('market')}/>
                {formik.touched.market && formik.errors.market? <div className="error">{formik.errors.market}</div> : null}
            </div>  
            <div>
                <Span>Banner Image</Span>   
                <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'> 
                    <Input type='text' border='none' radius='rounded' id='banner_image' value={fileMarket} disabled/>
                    <span className="browse">
                        <input 
                            type="file" 
                            name="banner" 
                            id="upload_banner" 
                            onChange={(e) => handleBannerMarket(e)} 
                            accept="image/*" 
                        />
                        <label htmlFor="upload_banner" className="cursor-pointer">Browse</label>
                    </span>
                </InputContainer>
                
                {imgMarket && <img src={img} alt='' className='banner'/>}
                {bannerMarket && <img src={previewBannerMarket} alt='' className='banner'/>}   
            </div>
        </GridContainer>

        <div className='text-right'>
            <Button 
                width='none' padding='px' type='lightgreen' margin='xy' radius='rounded'
                onClick={(e) => onSubmit(e)}
                disabled={!(formik.dirty && formik.isValid)}
            >
                Add Market
            </Button>

            <Button width='none' padding='px' type='grey' radius='rounded'
                onClick={() => window.location.reload()}
            >
                Cancel
            </Button>
        </div>
    </Container>
    </>);
}