import React from "react" 
import getallLogo from '../images/logo.png'  
import menu from '../images/Menu.png'

export default () => {  
    return (<>  
        <div className="header">  
            <label htmlFor="nav-toggle" className="m-auto">
                <img src={menu} alt="" className="menu-bar"/> 
            </label>

            <div className="header-logo"> 
                <img src={getallLogo} alt="" className="h-12 mx-auto"/>
            </div> 
        </div>
    </>)
}