import { CenterContainer, SubContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading" 
import { Span } from "../../components/common/Span"  
import { useState } from "react";
   
import ListEditTab from './ListEditTab' 

export default ({id,  ...props}:any) => {    

    return (<><main>
        <CenterContainer padding="header">
            <H1 weight='medium'>Edit Product </H1>  
            <div className="toggle-container"> 
                <Span weight="semibold">Product Details</Span>
            </div>
              
            <ListEditTab id={id}/> 
        </CenterContainer> 
        </main>
    </>)
}