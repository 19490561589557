import { useState } from "react";
import getallLogo from '../images/logo.png'
import { CenterContainer } from "../components/common/Container";
import {H1} from "../components/common/Heading"  
import { Button } from "../components/common/Button" 

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { authenticate } from "../network/auth"; 
import { useHistory } from "react-router-dom"; 
// import ErrorModal from '../components/ErrorModal'
  
  const initialValues = {
    email: '',
    password: '',
  }
  
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format!').required('Required!'),
    password: Yup.string().required('Required!')
  }) 

export default () => {   

  let history = useHistory();
 
  const [error, setError]= useState(false); 
  
  const onSubmit = async ({email, password}:any) => {
    
    let result = await authenticate(email, password);

    if(result){
      history.push('/dashboard')
    } else {
      return setError(true);
    } 
  } 
  
  const formik = useFormik({
    initialValues, 
    onSubmit,
    validationSchema
  }) 

  return (<>
    <CenterContainer align="center" flex="flex" bg="white">
      <div className="w-96">
        <img src={getallLogo} alt="" className="h-32 mx-auto"/>
        <H1 align="center" type="green" weight="medium">Content Management System</H1>  
        {error && <>
          <div className='text-center py-1 rounded border border-getAllRed-200 bg-getAllRed-300 bg-opacity-30'>
            <span className='text-xs'>
            Email or password is incorrect!
            </span> 
          </div>
        </>}
        <form action="" onSubmit={formik.handleSubmit} className="mt-5">
          <div className="form_group">
            <input type="email" id="email" className="form_field" placeholder="Email" 
            {...formik.getFieldProps('email')}/>
            <label htmlFor="email" className="form_label label">Email</label>
          </div>
          {formik.touched.email && formik.errors.email?<div className="error">{formik.errors.email}</div> : null}
           
          <div className="form_group">
            <input type="password" id="password" className="form_field" placeholder="Password" 
            {...formik.getFieldProps('password')}/>
            <label htmlFor="password" className="form_label label">Password</label>
          </div> 
          {formik.touched.password && formik.errors.password? <div className="error">{formik.errors.password}</div> : null}
                
            <Button radius="rounded">Login</Button>
          
        </form>
      </div>
    </CenterContainer>

    {/* {error &&
      <ErrorModal setError={setError}/>
    } */}
  </>)
}