import React from 'react'
import { useParams } from 'react-router'
import { MainContent } from '../components/common/Container'
import MerchantEditPage from '../containers/Merchant/MerchantEdit'  
import Navbar from '../containers/Navbar'
import Sidebar from '../containers/Sidebar'
 
const MerchantEdit = () => {
    
    let params:any = useParams();

    // console.log(params)

    return ( 
        <> 
            <Sidebar/>
            <MainContent>
                <Navbar /> 
                <MerchantEditPage id={params.id}/>
            </MainContent>
        </>
    )
}

export default MerchantEdit
