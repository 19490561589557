import React from 'react'
import { MainContent } from '../components/common/Container'
import Logout from '../containers/Logout'  
import Navbar from '../containers/Navbar'
// import Sidebar from '../containers/Sidebar'
 
export default () => {
    return (
        <>  
                <Navbar /> 
                <Logout/> 
        </>
    )
}
 
