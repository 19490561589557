import { useMemo } from 'react'   

import { COLUMNS } from '../Table/column'
import subcategorydata from '../Table/data.json'

import Table from '../../../components/Table'  
  
export default () => { 
    // Table
    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => subcategorydata, []) 

return (
    <>  
        <Table columns={columns} data={data}/>    
    </>
    );
}