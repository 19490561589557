import { useState } from 'react'  
import { GridContainer, InputContainer, Container } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button'

import img from '../../images/img.jpg'

import * as Yup from 'yup'
import { useFormik } from 'formik'
  
const initialValues = {
  market: '',
  featured: '',
}

const onSubmit = values => {
  console.log('Form data', values)
}

const validationSchema = Yup.object({ 
  market: Yup.string().required('Required!'),
  featured: Yup.string().required('Required!')
}) 
  
export default () => { 

    // Upload image 
    const [bannerAttrImg,setBannerAttrImg] = useState("")
    const [previewBannerAttrImg,setPreviewBannerAttrImg] = useState("")  
    const [imgAttr,setImgAttr] = useState(true)
    const [fileAttr, setFileAttr] = useState('Choose File');

    const handleBannerAttrImg = (e) => {
        e.preventDefault(); 
        setFileAttr(e.target.files[0].name);
        const selectedBannerAttrImg = e.target.files[0]
        setBannerAttrImg(selectedBannerAttrImg)
        const bannerAttrImgPreview = URL.createObjectURL(selectedBannerAttrImg)
        setPreviewBannerAttrImg(bannerAttrImgPreview)
        setImgAttr(false)
    } 

    // Formik
    const formik = useFormik({
        initialValues, 
        onSubmit,
        validationSchema
    })
 
    return (<>
    <Container bg='white' padding="x">
        <GridContainer bg='white' padding='top-sm'>
            <div>
                <Span>MommyMili Market</Span>
                <Input type='text' radius='rounded' placeholder='New MommyMili Market'
                    id='market'
                    {...formik.getFieldProps('market')}/>
                {formik.touched.market && formik.errors.market? <div className="error">{formik.errors.market}</div> : null}
            </div> 
            <div>
                <Span>Featured Market</Span>
                <Input type='text' radius='rounded' placeholder='New Featured Market'
                    id='featured'
                    {...formik.getFieldProps('featured')}/>
                {formik.touched.featured && formik.errors.featured? <div className="error">{formik.errors.featured}</div> : null}            
            </div> 
            <div>
                <Span>Banner Image</Span>   
                <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'> 
                    <Input type='text' border='none' value={fileAttr} disabled/>
                    <span className="browse">
                        <input type="file" name="banner" id="upload_banner" onChange={(e) => handleBannerAttrImg(e)} accept="image/*"/>
                        <label htmlFor="upload_banner" className="cursor-pointer">Browse</label>
                    </span>
                </InputContainer>
                
                {imgAttr && <img src={img} alt='' className='banner'/>}
                {bannerAttrImg && <img src={previewBannerAttrImg} alt='' className='banner'/>}   
            </div>
        </GridContainer>

        <div className='text-right'>
            <Button width='none' padding='px' type='lightgreen' margin='xy' radius='rounded'>Add Category</Button>
            <Button width='none' padding='px' type='grey' radius='rounded'>Cancel</Button>
        </div>
    </Container>
    </>);
}