/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from 'react'  
import { GridContainer,  Container } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button' 
import { Select } from '../../components/common/Select' 
import { useHistory, Link } from "react-router-dom"; 
 
import * as Yup from 'yup'
import { useFormik } from 'formik'
import api from '../../network/api';
import { createSubCategory } from '../../network/auth';
  
const initialValues = {
    subcategory: '',
    category: '', 
}
const validationSchema = Yup.object({ 
    subcategory: Yup.string().required('Required!'),
    category: Yup.string().required('Required!'),
}) 

export default () => { 

    const [data, setData] = useState([])

    useEffect(() => {
        api.get("get-category")
              .then(res => {
                  return res.data.data.category;
              })
              .then(data => {setData(data);
              }) 
      .catch(error => {
               console.log(error.message);
            })
      }, [])


    const formik = useFormik({
        enableReinitialize: true,
        initialValues, 
        onSubmit: () => {},
        validationSchema
    })

    
    const history = useHistory();
    const onSubmit = async (e) => {
        e.preventDefault();
        await createSubCategory(formik.values.subcategory, formik.values.category); 
        history.push("/subcategory");
        window.location.reload();
    }
    return (<>
    <form>
    <Container bg='white' padding="x">
        <GridContainer bg='white' padding='top-sm' grid='two'>
            <div>
                <Span>Sub Category Name</Span>
                <Input type='text' radius='rounded' placeholder='New Category' id='subcategory'
                    {...formik.getFieldProps('subcategory')}/>
                {formik.touched.subcategory && formik.errors.subcategory? <div className="error">{formik.errors.subcategory}</div> : null}
            </div> 
            
            <div>
                <Span>Main Category</Span>  
                <Select id='category' {...formik.getFieldProps('category')}>
                    <option value="" selected disabled hidden>-- Select --</option>
                
                    {data.map((category:any) => { 
                    return ( <option>{category.name}</option>);
                    })}
                </Select>
                {formik.touched.category && formik.errors.category? <div className="error">{formik.errors.category}</div> : null}
            </div>
         
        </GridContainer>

        <div className='text-right'>
            <Button 
                width='none' padding='px' type='lightgreen' margin='xy' radius='rounded' 
                onClick={(e) => onSubmit(e)} 
                disabled={!(formik.dirty && formik.isValid)}
            >
                Add Sub Category
            </Button>
             
            <Button width='none' padding='px' type='grey' radius='rounded'
                onClick={() => window.location.reload()}
            >
                Cancel
            </Button> 
        </div>
    </Container>
    </form>
    </>);
}