import { Link } from 'react-router-dom'
import { ColumnFilter } from "../../../components/ColumnFilter"; 

export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
    },
    {
        accessor: 'image',
        Header: 'Image',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
    },
    {
        accessor: 'market',
        Header: 'Mommymili Market',
        Filter: ColumnFilter,
        isVisible: true,        
    },
    {
        accessor: 'featured',
        Header: 'Featured Market',
        Filter: ColumnFilter,
        isVisible: true,    
    },
    {
        accessor: 'action',
        Header: 'Action',
        Filter: ColumnFilter,
        isVisible: true,
        disableFilters: true,
        Cell: data => <div className="flex justify-center">
                <Link to={`attribute/edit/${data.row.original.id}`} className="edit">Edit</Link>
                <Link to={`attribute/delete/${data.row.original.id}`} className="delete">Delete</Link>
            </div> 
    }
]