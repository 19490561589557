import { useState, useEffect } from "react"; 
import { CenterContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading"  
import { GridContainer, Container } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button' 
import { Select } from '../../components/common/Select'
import { useHistory, Link } from 'react-router-dom'
   
import * as Yup from 'yup'
import { useFormik } from 'formik'
import api from '../../network/api';
import UpdateModal from './Modal/UpdateModal'

const validationSchema = Yup.object({ 
    name: Yup.string().required('Required!'),
    category: Yup.string().required('Required!')
}) 

export default ({id, ...props}:any) => { 
    
    const [initialValues, setData] = useState({
        name: '',
        category: '',
    })

     
    // Get Data 
    const [error, setError]= useState(null);  
    const [categories, setCategory] = useState([]) 
    useEffect(() => { 

        api.get(`get-category`)
        .then(res => {
             setCategory(res.data.data.category ) 
        })
      .catch(error => {
                setError(error.message);
            })

        api.get(`get-one-subcategory/${id}`)
        .then(res => {
            setData({
                name: res.data.data[0].name,
                category : res.data.data[0].category_name
            }) 
        })
      .catch(error => {
                setError(error.message);
            }) 
    }, [])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues, 
        onSubmit: () => {},
        validationSchema
    })


    const history = useHistory(); 

    const onSubmit = (e) => {
        e.preventDefault();
        
        
        api.put(`update-subcategory/${id}`,
        {
            subcategory : formik.values.name,
            category : formik.values.category
        })
        .then(res => { 
            history.push("/subcategory");
            window.location.reload();
        }).catch(error => { 
            setError(error.message);
        }) 
    } 
     
    const [updateModal, setUpdateModal] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault(); 
        setUpdateModal(true);
    }

    return (<>
    <main>
        <CenterContainer padding="header">
            <H1 weight='medium'>Edit Sub Category</H1>  

            <div className="toggle-container"> 
                <Span weight="semibold">Sub Category Details</Span>
            </div> 

            <Container bg='white' padding="x">
                <GridContainer bg='white' padding='top-sm' grid='two'>
                    <div>
                        <Span>Sub Category Name</Span>
                        <Input type='text' radius='rounded' id="name"  placeholder="SubCategory"
                            {...formik.getFieldProps('name')} 
                        />
                        {formik.touched.name && formik.errors.name? <div className="error">{formik.errors.name}</div> : null}
                    </div> 
                    
                    <div>
                        <Span>Main Category</Span>  
                        <Select {...formik.getFieldProps('category')} >
                        <option selected disabled hidden>{initialValues.category}</option>
                        {categories.map((details:any) => { 
                            return ( <option>{details.name}</option>);
                            })}
                        </Select>
                        {formik.touched.category && formik.errors.category? <div className="error">{formik.errors.category}</div> : null}
                    </div>
                </GridContainer>
                <div className='text-right'>
                    <Button 
                        width='none' padding='px' type='lightgreen' margin='xy' radius='rounded' 
                        onClick={handleSubmit} 
                        // disabled={!(formik.dirty && formik.isValid)}
                    >
                        Update
                    </Button>
                    <Link  to="/subcategory">
                        <Button width='none' padding='px' type='grey' radius='rounded'>Cancel</Button>
                    </Link>
                </div>
            </Container>  
        </CenterContainer> 

        {updateModal &&
            <UpdateModal setUpdateModal={setUpdateModal} onSubmit={onSubmit}/>
        }
    </main> 

    </>)
}