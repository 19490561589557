import React from 'react'
import './App.css'; 
import { BrowserRouter, Switch, Route   } from 'react-router-dom'; 


import Login from './pages/index';
import Logout from './pages/logout'
import Dashboard from './pages/dashboard';
import Order from './pages/order';
import Process from './pages/process';
import DeliveryDetail from './pages/deliveryDetails';
import Attribute from './pages/attribute'; 
import AttributeEdit from './pages/attributeEdit'; 
import Category from './pages/category';   
import CategoryEdit from './pages/categoryEdit';   
import CategoryDelete from './pages/categoryDelete';  
import SubCategory from './pages/subcategory';     
import SubCategoryEdit from './pages/subcategoryEdit'; 
import BulkImport from './pages/bulkImport'; 
import List from './pages/list';  
import ListEdit from './pages/listEdit';  
import Users from './pages/users'; 
import Location from './pages/location'; 
import LocationEdit from './pages/locationEdit'; 
import PageNotFound from './pages/pageNotFound'; 
import Market from './pages/market'; 
import MarketEdit from './pages/marketEdit';
import Merchant from './pages/merchant'; 
import MerchantEdit from './pages/merchantEdit'; 

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login}/> 
        <Route path="/logout" exact component={Logout}/>
        <Route path="/dashboard" exact component={Dashboard}/>
        <Route path="/order" exact component={Order}/> 
        <Route path="/market" exact component={Market}/>  
        <Route path="/market/edit/:id" exact component={MarketEdit}/>  
        <Route path="/merchant" exact component={Merchant}/>  
        <Route path="/merchant/edit/:id" exact component={MerchantEdit}/>  
        <Route path="/process" exact component={Process}/> 
        <Route path="/order/details/:id" exact component={DeliveryDetail}/> 
        <Route path="/attribute" exact component={Attribute}/>
        <Route path="/attribute/edit/:id" exact component={AttributeEdit}/>  
        <Route path="/category" exact component={Category}/>  
        <Route path="/category/edit/:id" exact component={CategoryEdit}/>   
        <Route path="/category/delete/:id" exact component={CategoryDelete}/> 
        <Route path="/subcategory" exact component={SubCategory}/>
        <Route path="/subcategory/edit/:id" exact component={SubCategoryEdit}/>   
        <Route path="/list" exact component={List}/>
        <Route path="/list/edit/:id" exact component={ListEdit}/>  
        <Route path="/bulkImport" exact component={BulkImport}/> 
        <Route path="/location" exact component={Location}/>
        <Route path="/location/edit/:id" exact component={LocationEdit}/>   
        <Route path="/users" exact component={Users}/> 
        <Route path="/pagenotfound" exact component={PageNotFound}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
