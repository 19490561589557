import React from 'react'
import { MainContent } from '../components/common/Container'
import LocationPage from '../containers/Location/LocationPage'  
import Navbar from '../containers/Navbar'
import Sidebar from '../containers/Sidebar'
 
const Location = () => {
    return ( 
        <> 
            <Sidebar/>
            <MainContent>
                <Navbar /> 
                <LocationPage/>
            </MainContent>
        </>
    )
}

export default Location
