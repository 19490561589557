import React from 'react'
import { useParams } from 'react-router'
import { MainContent } from '../components/common/Container'
import MarketEditPage from '../containers/Market/MarketEditPage'  
import Navbar from '../containers/Navbar'
import Sidebar from '../containers/Sidebar'
 
const MarketEdit = () => {
    
    let params:any = useParams();

    // console.log(params)

    return ( 
        <> 
            <Sidebar/>
            <MainContent>
                <Navbar /> 
                <MarketEditPage id={params.id}/>
            </MainContent>
        </>
    )
}

export default MarketEdit
