import { useState } from 'react' 
import { ModalContainer } from '../../../components/common/Container'
import { ModalCloseButton, Button } from '../../../components/common/Button' 
import { Span } from '../../../components/common/Span'
import { IoClose } from 'react-icons/io5'
import { IoMdUnlock } from 'react-icons/io'     

import FormControl from '@material-ui/core/FormControl'; 
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input'; 
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment'; 
import { MdVisibility, MdVisibilityOff } from "react-icons/md"; 

import * as Yup from 'yup'
import { useFormik } from 'formik'

type Props = {
    setChangePasswordModal: Function, 
  }
  
const initialValues = {
    password: '',
    confirmpassword: '',  
}

const onSubmit = values => {
  console.log('Form data', values)
}

const validationSchema = Yup.object({ 
    password: Yup.string().min(8, 'Must be atleast 8 characters').required('Required!'),
    confirmpassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and confirm password don't match!"
        )
    }),
}) 


export default ({setChangePasswordModal,...props}: Props) => { 
    const [showPw, setshowPw] = useState(false);
    const togglePw = (e) => {
        e.preventDefault();
        setshowPw(!showPw);
    };

    const [showConfirmPw, setshowConfirmPw] = useState(false);
    const toggleConfirmPw = (e) => {
        e.preventDefault();
        setshowConfirmPw(!showConfirmPw);
    };
   
    // Formik
    const formik = useFormik({
        initialValues, 
        onSubmit,
        validationSchema
    })

  return (<>   
    <ModalContainer bg='darkgreen'>
        <div className="flex pt-1.5"> 
            <ModalCloseButton onClick={() => setChangePasswordModal("")} > 
                <IoClose className="text-2xl"/> 
            </ModalCloseButton>    
            <div className='flex p-6'>
                <IoMdUnlock className='text-2xl my-auto mx-3.5 p-1 bg-white bg-opacity-25 rounded-md'/> 
                <Span size='2xl' type='white'>Create New Password</Span>
            </div> 
        </div> 
        <div className="px-20 whitespace-normal text-left"> 
            <Span size='base' type='white'>
                We will send a verification code to your registered mobile number.
            </Span>

            <div className='mx-auto my-8 text-base'>   
            <FormControl className="mt-5">
                <InputLabel htmlFor="password" className="white">Password</InputLabel>
                <Input
                    className="white border-white"
                    id="password"
                    type={showPw ? 'text' : 'password'} 
                    {...formik.getFieldProps('password')}  
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        className="white"
                        aria-label="toggle password visibility"
                        onClick={togglePw} 
                        >
                        {showPw ? <MdVisibilityOff /> : <MdVisibility />}
                        </IconButton>
                    </InputAdornment>
                }
                />
            </FormControl>
            {formik.touched.password && formik.errors.password? <div className="error-white">{formik.errors.password}</div> : null}
                    
            <FormControl className="mt-5">
                <InputLabel htmlFor="password" className="white">Confirm Password</InputLabel>
                <Input 
                    className="white"
                    id="confirmpassword"
                    type={showConfirmPw ? 'text' : 'password'} 
                    {...formik.getFieldProps('confirmpassword')}  
                    endAdornment={
                <InputAdornment position="end">
                    <IconButton 
                        className="white"
                        aria-label="toggle password visibility"
                        onClick={toggleConfirmPw} 
                    >
                    {showConfirmPw ? <MdVisibilityOff /> : <MdVisibility />}
                    </IconButton>
                </InputAdornment>
                }
                />
            </FormControl>
            {formik.touched.confirmpassword && formik.errors.confirmpassword? <div className="error-white">{formik.errors.confirmpassword}</div> : null}
                    
            </div> 
 
            <Button type='white' radius='rounded'>
                <Span size='lg' type='lightgreen' weight='medium'>Reset Password</Span>
            </Button>  
        </div> 
    </ModalContainer>
     
    <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
  </>);
}   