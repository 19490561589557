import React from 'react'
import { Link } from 'react-router-dom'
import { ColumnFilter } from "../../../components/ColumnFilter"; 
import { Button } from "../../../components/common/Button"; 
import ResetPassword from './ResetPassword';

export const COLUMNS = [
    {
        accessor: 'employee_id',
        Header: 'Employee ID', 
        Filter: ColumnFilter,
        isVisible: true
    },
    {
        accessor: 'lastname',
        Header: 'Last Name',
        Filter: ColumnFilter,
        isVisible: true, 
    },
    {
        accessor: 'firstname',
        Header: 'First Name',
        Filter: ColumnFilter,
        isVisible: true,        
    },
    {
        accessor: 'email',
        Header: 'Email Address', 
        Filter: ColumnFilter,
        isVisible: true,   
    },
    {
        accessor: 'role',
        Header: 'Role',
        Filter: ColumnFilter,
        isVisible: true, 
    },
    {
        accessor: 'mobile',
        Header: 'Mobile Number',
        Filter: ColumnFilter,
        isVisible: true, 
    },
    {
        accessor: 'action',
        Header: 'Action',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
        Cell: data => <div>
            <div className="flex justify-center mb-1.5">
                {/* <Button className="edit" onClick={(e) => handleEdit(e)}>Edit</Button> */}
                <Link to={`users/delete/${data.row.original.employee_id}`} className="delete">Delete</Link>
                <Link to={`users/disable/${data.row.original.employee_id}`} className="disable">Disable</Link>
            </div>  
            <ResetPassword/>
            </div> 
    }
]