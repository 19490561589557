import React from "react";

import category from '../images/category.png'
import product from '../images/product.png'
import users from '../images/users.png'


import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp }from "react-icons/md";  
import { GoPrimitiveDot } from "react-icons/go"; 
import { MdStore } from "react-icons/md";
import { IoCartSharp } from "react-icons/io5";
import { FaStore } from "react-icons/fa";


export const SubMenuData = [
    {
        title: 'Order',
        path: '/order',
        icon: <IoCartSharp className='cart'/> 
    },
    {
        title: 'Market',
        path: '/market',
        icon: <MdStore className='cart'/> 
    },
    {
        title: 'Merchant',
        path: '/merchant',
        icon: <FaStore className='merchant'/> 
    },  
    {
        title: 'Category',
        path: '/category',
        icon: <img src={category}/> 
    },
    {
        title: 'Sub Category', 
        path: '/subcategory',
        icon: <img src={category}/> 
    },
    {
        title: 'Product', 
        path: '/list',
        icon: <img src={product} className="bag-icon"/> 
        // iconClosed: <MdOutlineKeyboardArrowDown />, 
        // iconOpened: <MdOutlineKeyboardArrowUp />,
        // subNav: [
        //     {
        //         title: 'List',
        //         path: '/list',
        //         icon: <GoPrimitiveDot/>,
        //     },
            // {
            //     title: 'Bulk Import',
            //     path: '/bulkImport',
            //     icon: <GoPrimitiveDot/>,
            // } 
        // ]
    }
]