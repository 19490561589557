import React from 'react'
import { MainContent } from '../components/common/Container'
import OrderPage from '../containers/Order/OrderPage'  
import Navbar from '../containers/Navbar'
import Sidebar from '../containers/Sidebar'
 
const Order = () => {
    return ( 
        <> 
            <Sidebar/>
            <MainContent>
                <Navbar /> 
                <OrderPage/>
            </MainContent>
        </>
    )
}

export default Order
