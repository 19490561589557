import React, { useState } from "react"
import mommymiliLogo from '../images/Mommymili.png'
import dashboard from '../images/dashboard.png'

import { Button } from "../components/common/Button"
import { Link } from 'react-router-dom' 

import { SubMenuData } from '../components/SubMenuData'
import SubMenu from "../components/SubMenu"
import LogoutModal from '../components/LogoutModal'

export default () => { 

    const [logoutModal, setLogoutModal] = useState(false)
    const handleLogout = (e) => {
        e.preventDefault(); 
        setLogoutModal(true);
    }

    return (<> 
    <input type="checkbox" id="nav-toggle"/>
    <div className="bg-getAllGreen-200 sidebar">
        <div className="sidebar-brand">
            <img src={mommymiliLogo} alt=""/>
        </div>
        <div className="sidebar-menu">
            <ul>
                <li className="menu">
                    <Link to="/dashboard">
                        <img src={dashboard}/>
                        <span className="pt-2.5 pb-1.5">Dashboard</span>
                    </Link>
                </li>
                <li className="category">
                    <span>Management</span>
                </li> 

                {SubMenuData.map((item, index) =>{
                    return <SubMenu item={item} key={index}/>;
                })}

            </ul> 
        </div>
        <div className="px-2.5 absolute inset-x-0 bottom-0">
            {/* <Link to="/logout"> */}
            <Button type="lightgreen" onClick={handleLogout}>Logout</Button>
            {/* </Link> */}
        </div>

        
    </div> 
    
    {logoutModal &&
        <LogoutModal setLogoutModal={setLogoutModal}/>
    }  
    </>)
}