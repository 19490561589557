import {useState} from 'react'  
import { Link } from 'react-router-dom';





const SubMenu = ({item} : { item:any }) => {

    const [subnav, setSubnav] = useState(false) 
    const showSubnav = () => setSubnav(!subnav);

    return ( <>
        <li className="sub-menu" onClick={item.subNav && showSubnav}>
            <Link to={item.path} > 
                <div className="flex">
                    {item.icon}
                    <span className="pt-2.5 pb-1.5">{item.title}</span>
                </div>

                <div className="arrow-dropdown"> 
                    {item.subNav && subnav 
                    ? item.iconOpened 
                    : item.subNav
                    ? item.iconClosed 
                    : null}  
                </div>  
            </Link>
        </li>
        <ul className="lightgreen">
        { subnav && item.subNav.map((item:any, index:any) => {
            return (<> 
                <li className="subMenuLi">
                    <Link to={item.path} className="subLink">
                        <span>{item.icon}</span>
                        <label>{item.title}</label>
                    </Link>
                </li>
            </>) 
        })}</ul> 
    </>);
};

export default SubMenu;