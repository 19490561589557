import { useState } from "react";
import ResetPasswordModal from '../Modal/ResetPasswordModal'

const ResetPassword = () => {
   const [modalReset, setResetModal] = useState(false); 

    return ( 
    <div className='flex mx-0.5'>
        <a onClick={() => {setResetModal(true);}} className="reset">
            Reset Password
        </a>

        {modalReset && <ResetPasswordModal setResetModal={setResetModal}/>}
    </div>
    )
}

export default ResetPassword

            