import React from 'react'
import { MainContent } from '../components/common/Container'
import MarketPage from '../containers/Market/MarketPage'  
import Navbar from '../containers/Navbar'
import Sidebar from '../containers/Sidebar'
 
const Market = () => {
    return ( 
        <> 
            <Sidebar/>
            <MainContent>
                <Navbar /> 
                <MarketPage/>
            </MainContent>
        </>
    )
}

export default Market
