import { CenterContainer, SubContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading" 
import { Span } from "../../components/common/Span"  
import { useState } from "react";

import LocationEditTab from './LocationEditTab'
import Table from './Table/Table'

export default () => { 

    const [locationEdit, setLocationEdit] = useState(false);
    const toggleLocationEdit = (e:any) => {
        e.preventDefault();
        setLocationEdit(!locationEdit);
      }; 

    return (<><main>
        <CenterContainer padding="header">
            <H1 weight='medium'>Location</H1> 
 
            <div className="toggle-container" onClick={toggleLocationEdit}>
                <i className="material-icons my-auto">
                    {locationEdit  ? 'expand_more' : 'chevron_right' }
                </i>   
                <Span weight="semibold">New Location</Span>
            </div>
            
            {locationEdit ? (
                <>
                    <LocationEditTab/>
                </>
            ) : null} 

             
            <SubContainer flex='flex' bg='white' border='grey' margin='top' borderWidth='bottom' padding='normal'> 
                <Span weight="semibold">Category List</Span>
            </SubContainer> 
                <Table/> 
        </CenterContainer> 
        </main>
    </>)
}