import axios from 'axios'
import { API_URL } from './constants'
import { store } from "../store";

let token = localStorage.getItem('token')

// Add a response interceptor
axios.interceptors.response.use((response) => {
  return response;
}, (error)=>{
  store.dispatch.UI.error(error.response?.data.message);
  return error;
});

axios.defaults.baseURL = API_URL
axios.defaults.headers.common = {'Authorization': `bearer ${token}`}
export default axios;
