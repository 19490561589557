import { ModalContainer } from './common/Container'
import { Button } from './common/Button' 
import { Span } from './common/Span'  
import { Link } from "react-router-dom"; 
import question from '../images/question.png'
    
    type Props = {
        setLogoutModal: Function  
    } 
  
export default ({setLogoutModal, ...props}: Props) => { 
      
    return (<>   
    <ModalContainer bg='white' radius='rounded'> 
        
        <div className="px-20 py-4 whitespace-normal text-center "> 
            <div className='mt-5 mb-2.5'>
                <img src={question} className='h-16 my-2 mx-auto'/> 
                <Span size='2xl' weight='bold'>Are you sure?</Span>
            </div>
            <Span size='base'>
                Want to logout
            </Span> 
            
            <div className='text-center'> 
                <Button width='none' padding='px' type='white' border='grey' radius='rounded'
                    onClick={() => setLogoutModal(false)}
                >
                    Cancel
                </Button>  
                
                <Link to="/logout">
                    <Button 
                        width='none' padding='px' type='lightgreen' border='green'  margin='y' radius='rounded'
                    >
                        Logout
                    </Button>
                </Link>
            </div> 
        </div> 
    </ModalContainer> 

  <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
  </>);
}   