import { Link } from 'react-router-dom'
import { ColumnFilter } from "../../../components/ColumnFilter"; 

export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
    },
    {
        accessor: 'location',
        Header: 'Location',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
    }, 
    {
        accessor: 'category',
        Header: 'Category',
        Filter: ColumnFilter,
        isVisible: true,        
    },
    {
        accessor: 'subcategory',
        Header: 'Sub Category',
        Filter: ColumnFilter,
        isVisible: true,    
    },
    {
        accessor: 'product',
        Header: 'Product',
        Filter: ColumnFilter,
        isVisible: true,    
    },
    {
        accessor: 'action',
        Header: 'Action',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
        Cell: data => <div className="flex justify-center">
                <Link to={`location/disable/${data.row.original.id}`} className="disable">Disabled</Link>
                <Link to={`location/edit/${data.row.original.id}`} className="edit">Edit</Link>
                <Link to={`location/delete/${data.row.original.id}`} className="delete">Delete</Link>
            </div> 
    }
]