/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from 'react'  
import { GridContainer, InputContainer, Container, SubContainer } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button' 
import { Select } from '../../components/common/Select'
import {  useHistory } from "react-router-dom"; 
import Switch from "react-switch";
 
import img from '../../images/img.jpg' 

import * as Yup from 'yup'
import { useFormik } from 'formik'
import api from '../../network/api';
// import Swal from 'sweetalert2';
  
const initialValues = {
    market: '',
    merchant: '', 
    featured: '',
    checked: ''
}

const validationSchema = Yup.object({ 
    market: Yup.string().required('Required!'),
    merchant: Yup.string().required('Required!')
}) 

export default () => { 

    // Upload image 
    const [coverMerchant,setCoverMerchant] = useState("")
    const [previewCoverMerchant,setPreviewCoverMerchant] = useState("") 
    const [imgCoverMerchant,setImgCoverMerchant] = useState(true);
    const [fileCoverMerchant, setFileCoverMerchant] = useState('Choose File'); 

    const [bannerMerchant,setBannerMerchant] = useState("")
    const [previewBannerMerchant,setPreviewBannerMerchant] = useState("")
    const [imgBannerMerchant,setImgBannerMerchant] = useState(true); 
    const [fileBannerMerchant, setFileBannerMerchant] = useState('Choose File');

    const handleCoverMerchant = (e) => {
        e.preventDefault(); 
        setFileCoverMerchant(e.target.files[0].name);
        const selectedCoverMerchant = e.target.files[0]
        setCoverMerchant(selectedCoverMerchant)
        const coverMerchantPreview = URL.createObjectURL(selectedCoverMerchant)
        setPreviewCoverMerchant(coverMerchantPreview)
        setImgCoverMerchant(false)
    } 

    const handleBannerMerchant = (e) => {
        e.preventDefault(); 
        setFileBannerMerchant(e.target.files[0].name);
        const selectedBannerMerchant = e.target.files[0]
        setBannerMerchant(selectedBannerMerchant)
        const bannerMerchantPreview = URL.createObjectURL(selectedBannerMerchant)
        setPreviewBannerMerchant(bannerMerchantPreview)
        setImgBannerMerchant(false)
    } 

    // Formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues, 
        onSubmit: () => {},
        validationSchema
    })

    const [error, setError]= useState(null);
    const history = useHistory();
    const onSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('merchant',  formik.values.merchant)
        formData.append('market',  formik.values.market)
        formData.append('featured',  featured)
        formData.append('thumbnail_image', coverMerchant)
        formData.append('banner_image', bannerMerchant)
        api.post('save-merchant',formData).then(res => { 
            history.push("/merchant");
            window.location.reload(); 
        }).catch(error => {
            setError(error.message);
        })
    }

    
    const [market, setMarket] = useState([]) 
    useEffect(() => {
      api.get("get-market")
            .then(res => {
                return res.data.data.market;
            })
            .then(market => {setMarket(market);
            })
            .catch(error => {
                console.log(error)
            })
    }, []) 

    const [checked, setChecked] = useState(false) 
    const [featured, setFeatured] : any = useState(0) 
    useEffect(() => {
        if(checked){
            setFeatured(1)
        }else{
            setFeatured(0)
        }
    }, [checked])

    const handleChange =(checked)=> {
        setChecked(checked );
      }

    return (<>
    <form>
    <Container bg='white' padding="x">
        <GridContainer bg='white' padding='top-sm' grid='two'>
            <div>
                <Span>Merchant</Span>
                <Input type='text' radius='rounded' placeholder='New Merchant' id='merchant'
                    {...formik.getFieldProps('merchant')}/>
                {formik.touched.merchant && formik.errors.merchant? <div className="error">{formik.errors.merchant}</div> : null}
            </div> 

            <div>
                <Span>Market</Span>
                <Select id='market' {...formik.getFieldProps('market')}>
                    <option value="" selected disabled hidden>-- Select --</option>
                    {market.map((markets:any) => { 
                    return ( <option>{markets.name}</option>);
                    })}
                </Select>
                
                {formik.touched.market && formik.errors.market? <div className="error">{formik.errors.market}</div> : null}
            </div> 
          
        </GridContainer>
        <GridContainer bg='white' padding='none' grid='two'>
            <div>
                <Span>Cover Image</Span>  
                <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'>
                    <Input type='text' border='none' radius='rounded' id='thumbnail_image' value={fileCoverMerchant} disabled/>
                    <span className="browse">
                        <input type="file" name="cover" id="upload_cover" onChange={(e) => handleCoverMerchant(e)} accept="image/*"/>
                        <label htmlFor="upload_cover" className="cursor-pointer">Browse</label> 
                    </span> 
                </InputContainer>
                
                {imgCoverMerchant && <img src={img} alt='' className='cover'/>}
                {coverMerchant && <img src={previewCoverMerchant} alt='' className='cover'/>}   
            </div>
            <div>
                <Span>Banner Image</Span>  
                <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'>
                    <Input type='text' border='none' radius='rounded'id='banner_image' value={fileBannerMerchant} disabled/>
                    <span className="browse">
                        <input type="file" name="banner" id="upload_banner" onChange={(e) => handleBannerMerchant(e)} accept="image/*"/>
                        <label htmlFor="upload_banner" className="cursor-pointer">Browse</label> 
                    </span>
                </InputContainer>

                {imgBannerMerchant && <img src={img} alt='' className='banner'/>}
                {bannerMerchant && <img src={previewBannerMerchant} alt='' className='banner'/>}   
            </div>
        </GridContainer>

        <SubContainer flex='flex' bg='white' padding='top-sm'> 
            <Span> Featured</Span>
            <div className='mx-6'>
                <Switch 
                    onChange={handleChange} 
                    checked={checked}
                    checkedIcon={false}
                    uncheckedIcon={false} 
                     onColor='#9fbf15'
                />
            </div>
        </SubContainer>  
             

        <div className='text-right'>
            <Button 
                width='none' padding='px' type='lightgreen' margin='xy' radius='rounded' 
                onClick={(e) => onSubmit(e)}
                disabled={!(formik.dirty && formik.isValid)}
            >
                Add Merchant
            </Button>
            <Button 
                width='none' padding='px' type='grey' radius='rounded'
                onClick={() => window.location.reload()}
            >
                Cancel
            </Button>
        </div>
    </Container>
    </form>
    </>);
}