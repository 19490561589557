import { useMemo } from 'react'  

import { COLUMNS } from '../Table/column'
import locationdata from '../Table/data.json' 

import Table from '../../../components/Table'  

export default () => { 
    // Table
    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => locationdata, [])

return (
    <>  
        <div className="table-md">
            <Table columns={columns} data={data}/>  
        </div>  
    </>
    );
}