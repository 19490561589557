import * as React from 'react';
import classnames from 'classnames';

type Props = React.HTMLProps<HTMLInputElement> & { 
  radius?: 'rounded' | 'left',
  border?: 'green' | 'none' | 'bottom',
  width?: 'none',
  bg?: 'green',
  align?: 'center',
}


let getBorder = (border: Props['border']) => {
    switch (border) { 
      case 'bottom':
        return 'border-b-2 border-white'
      case 'none':
        return 'border-none'
      case 'green':
        return 'border border-getAllGrey-300 focus:border-getAllGreen-100'; 
      default:
        return 'border border-getAllGrey-100 focus:border-getAllGreen-300';
    }
  }

let getRadius = (radius: Props['radius']) => {
  switch (radius) {
    case 'left':
      return 'rounded-l rounded-r-xl'; 
    case 'rounded':
      return 'rounded'; 
    default:
      return 'rounded-none';
  }
}
 
let getWidth = (width: Props['width']) => {
  switch (width) { 
    case 'none':
      return ''; 
    default:
      return 'w-full';
  }
} 

let getBg = (bg: Props['bg']) => {
  switch (bg) { 
    case 'green':
      return 'bg-getAllGreen-200'; 
    default:
      return 'bg-white';
  }
}  

let getAlign = (align: Props['align']) => {
  switch (align) { 
    case 'center':
      return 'text-3xl text-center'; 
    default:
      return 'text-left';
  }
} 

 
export const Input = ({ radius, border, width, bg, align, children, ...props}: Props) => { 
  return <input className={classnames("py-1 px-1.5 focus:outline-none", getBg(bg),
            getRadius(radius), getBorder(border), getWidth(width), getAlign(align)
          )} { ...props } />
} 