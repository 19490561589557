import React from 'react'
import { MainContent } from '../components/common/Container'
import ProductListPage from '../containers/ProductList/ProductLstPage'  
import Navbar from '../containers/Navbar'
import Sidebar from '../containers/Sidebar'
 
const List = () => {
    return ( 
        <> 
            <Sidebar/>
            <MainContent>
                <Navbar /> 
                <ProductListPage/>
            </MainContent>
        </>
    )
}

export default List
