import { createModel } from "@rematch/core";
// import { login } from "../network/auth";
// import recover from "../pages/recover";
import { RootModel } from "./index";
import { AlertType } from "react-alert";
import moment from "moment";
// import Swal from 'sweetalert2';

interface IAlertPayload {
    message: string;
    type: AlertType;
  }

const InitialState = {
  loading:false,
  alert: {
      message: "",
      type: "info",
      alertWatcher: moment.now()
  },
}
export const UI = createModel<RootModel>()({
  state: InitialState,
  reducers: {

    setWarning(state, message: string) {
      return {
          ...state,
          alert: {...state.alert, message, type:'info', alertWatcher: moment.now()}
      }
    },
    setSuccessAlert(state, message: string) {
      return {
          ...state,
          alert: {...state.alert, message, type:'success', alertWatcher: moment.now()}
      }
    },
    setLoading(state, loading: boolean) {
      return {
          ...state,
          loading
      }
    }
  },
  effects: (dispatch) => ({
    async error(message:string, state) {
      // Swal.fire({
      //   title: '<strong>Error!</strong>',
      //   icon: 'error',
      //   text: message,
      //   // showCloseButton: true,
      //   confirmButtonColor: '#021d49',
      //   confirmButtonText: 'Okay'
      //   // timer: 1500
      // })
    },
    async warning(message:string, state) {
      // dispatch.UI.setWarning(message);
      // Swal.fire({
      //   title: '<strong>Warning!</strong>',
      //   icon: 'info',
      //   text: message,
      //   confirmButtonColor: '#021d49',
      //   confirmButtonText:  'Okay'
      // })
    },
    async success(message:string, state) {
      // dispatch.UI.setSuccessAlert(message);
      // Swal.fire({
      //   title: '<strong>Success!</strong>',
      //   icon: 'success',
      //   text: message,
      //   confirmButtonColor: '#021d49',
      //   confirmButtonText:  'Okay'
      // })
    },
    async loading(loading:boolean, state){
      dispatch.UI.setLoading(loading);
    }
})
});