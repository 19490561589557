import { useMemo, useEffect, useState } from 'react'     
import { Button } from '../../../components/common/Button';   
import { Span } from '../../../components/common/Span';  
import { Input } from '../../../components/common/Input'
import  { useTable, useFilters, usePagination } from 'react-table'
import { Link } from 'react-router-dom'

import { ColumnFilter } from '../../../components/ColumnFilter'
import { COLUMNS } from '../Table/column'
import usersdata from '../Table/data.json' 

import Table from './Table'

export default () => { 
    // Table
    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => usersdata, []) 

    const tableInstance = useTable({ columns, data, defaultColumn: { Filter: ColumnFilter }, },
        useFilters, usePagination
    ) 

    const { 
        getTableProps, 
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setPageSize,
        state, 
        setHiddenColumns,
        prepareRow,
    } = tableInstance

    const { pageIndex, pageSize } = state

    useEffect(() => {
        console.log(columns.filter(column => !column.isVisible));
        setHiddenColumns(
          columns.filter(column => !column.isVisible).map(column => column.accessor)
        );
      }, [setHiddenColumns, columns]); 

    
    const [edit,setEdit] = useState(false); 

    const handleEdit= (e) => {
        e.preventDefault();   
        setEdit(true)
    }

    return (
    <div className="table-sm">
        <table {...getTableProps()} border='0' cellspacing='0' cellpadding='0'>
            <thead>
                {headerGroups.map((headerGroup) => (
                <tr  {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                    <th width={column.width} {...column.getHeaderProps()}>
                        <div className='bg-white font-semibold px-3.5 py-4'>{column.render('Header')} </div>
                        <div className='pt-2 mx-1'>{column.canFilter ? column.render('Filter') : null}</div> 
                    </th>
                    ))}
                </tr>
                ))}
                {edit && <tr className='border-b-8 border-getAllGrey-102 bg-white'>
                    <td></td>
                    <td>
                        <Input 
                            type="text" 
                            radius='rounded' 
                            // width='none' value={value.lastname} 
                            // onChange={(e) =>{value.updateValue(e, "lastname")}}
                            />
                            </td>
                    <td>
                        <Input 
                            type="text" 
                            radius='rounded' 
                            // width='none' value={value.firstname} 
                            // onChange={(e) =>{value.updateValue(e, "firstname")}}
                            />
                            </td>
                    <td>
                        <Input 
                            type="text" 
                            radius='rounded' 
                            // width='none' value={value.email} 
                            // onChange={(e) =>{value.updateValue(e, "email")}}
                            />
                            </td>
                    <td>
                        <Input 
                            type="text" 
                            radius='rounded' 
                            // width='none' value={value.role} 
                            // onChange={(e) =>{value.updateValue(e, "role")}}
                            /></td> 
                    <td>
                        <Input 
                            type="text" 
                            radius='rounded' 
                            // width='none' value={value.mobile} 
                            // onChange={(e) =>{value.updateValue(e, "mobile")}}
                            />
                            </td>
                    <td> 
                        <Button type='green' width='none' margin='auto' padding='px'>Update</Button>
                        <Button type='grey' width='none' margin='auto' padding='px'>Cancel</Button>
                    </td>
                </tr>}
            </thead>
        
            <tbody {...getTableBodyProps()}> 
                {page.map((row) => {prepareRow(row);
                return (
                    <tr {...row.getRowProps()} className='border-b-8 border-getAllGrey-102'>
                        {row.cells.map((cell) => {
                            return <td {...cell.getCellProps()} className='text-center'>{cell.render('Cell')}</td>;
                        })}
                    </tr>
                );
                })} 
            </tbody>
        </table> 
        <div className='flex justify-between'>
            <div className='my-4'>
                <select
                    className='px-2 rounded border border-getAllGrey-200 page'
                    value={pageSize} 
                    onChange={(e) => setPageSize(Number(e.target.value))}>
                        {[10,25,50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize} rows
                            </option>
                        ))}
                </select>
            </div>

            <div>
                <Button 
                    width='none' padding='px' type='white' radius='left' color='black' border='darkgrey'
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    Previous
                </Button>
                <Span bg='green' margin='auto' padding='px' type='white'>  
                    {pageIndex + 1} 
                </Span>
                <Button 
                    width='none' padding='px' type='white' radius='right' color='black' border='darkgrey' 
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                >
                    Next
                </Button>
            </div> 
        </div>    
    </div> 
    ); 
}