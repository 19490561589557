import { Link } from 'react-router-dom'
import { ColumnFilter } from "../../../components/ColumnFilter";   
import DeleteSubCategory from '../DeleteSubCatgry';

export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter,
        disableFilters: true, 
        isVisible: true,    
    }, 
    {
        accessor: 'subcategory', //subcategory
        Header: 'Sub Category',
        Filter: ColumnFilter, 
        isVisible: true,
    },
    {
        accessor: 'category',
        Header: 'Category',
        Filter: ColumnFilter,
        isVisible: true,    
    },
    {
        accessor: 'action',
        Header: 'Action',
        Filter: ColumnFilter,
        isVisible: true,
        disableFilters: true,
        Cell: data => <div className="flex justify-center">
                {/* <Link to={`subcategory/disable/${data.row.original.id}`} className="disable">Disabled</Link> */}
                <Link to={`subcategory/edit/${data.row.original.id}`} className="edit">Edit</Link>
                {/* <Link to={`subcategory/delete/${data.row.original.id}`} className="delete">Delete</Link> */}
                <DeleteSubCategory id={data.row.original.id}/>
            </div> 
    }
]