import React from 'react'
import BrokenLink from '../containers/BrokenLink'  
 
const PageNotFound = () => {
    return ( 
        <> 
            <BrokenLink/>  
        </>
    )
}

export default PageNotFound
