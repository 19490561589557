import { useState } from 'react'
import { useAsyncDebounce } from 'react-table' 
import { InputContainer } from '../../../components/common/Container';  
import { FiSearch } from 'react-icons/fi'

export const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, }) => {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

    return (  
      <InputContainer flex='flex' bg='white' radius="rounded" border='grey' borderWidth="border">
        <FiSearch className='my-auto mx-3 text-getAllGreen-200'/>
        <input
          className="p-1.5 focus:outline-none border-none rounded-r-lg"
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={'Search with anything'}
        />
      </InputContainer> 
    );
  }