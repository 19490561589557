import * as React from 'react';
import classnames from 'classnames'

type Props = {
  children: React.ReactNode, 
  type?: 'white' |  'green' | 'lightgreen' | 'darkgreen' | 'grey' | 'lightgrey',
  weight?: 'medium' | 'semibold' | 'bold',
  align?: 'center',
  padding?: 'normal' | 'px-normal' | 'px' | 'py' | 'px-lg' | 'py-2.5' | 'all' | 'none' | 'pr-5' | 'pr-7',
  transform?: 'uppercase',
  margin?: 'auto' | 'bottom' | 'top' | 'x-auto',
  size?: 'base' | 'lg' | '2xl' | '3xl' | '4xl',
  bg?: 'white' | 'green' | 'red' | 'orange',
  radius?: 'rounded',
  border?: 'grey' | 'lightgrey' | 'tb-grey'
}

let getStyle = (type: Props['type']) => {
switch (type) {  
  case 'lightgrey':
    return 'text-getAllGrey-106'; 
  case 'grey':
    return 'text-getAllGrey-100'; 
  case 'darkgreen':
    return 'text-getAllGreen-400' 
  case 'lightgreen':
    return 'text-getAllGreen-600' 
  case 'green':
    return 'text-getAllGreen-200' 
  case 'white':
    return 'text-white';
  default:
    return "text-getAllBlack-100";
}}

let getWeight = (weight: Props['weight']) => {
switch (weight) { 
    case 'bold':
      return 'font-bold'; 
    case 'semibold':
      return 'font-semibold'; 
    case 'medium':
      return 'font-medium'; 
    default:
      return 'font-normal';
}
}

let getAlign = (align: Props['align']) => {
  switch (align) { 
      case 'center':
          return 'text-center'; 
      default:
          return 'text-left';
  }
}

let getPadding= (padding: Props['padding']) => {
  switch (padding) {  
    case 'py':
      return 'py-2'
    case 'py-2.5':
      return 'px-5 py-2.5';  
    case 'px':
      return 'px-5 pt-2 pb-2.5';
    case 'pr-5':
      return 'pr-5'  
    case 'pr-7':
    return 'pr-7'  
    case 'px-lg':
      return 'px-20';  
    case 'px-normal':
      return 'px-5 py-1.5';
    case 'all':
      return 'p-2'; 
    case 'normal':
      return 'px-1'; 
    case 'none':
      return ''; 
    default:
      return 'px-0.5';
  }
} 

let getfontSize= (size: Props['size']) => {
  switch (size) { 
    case '4xl':
      return 'text-4xl'; 
    case '3xl':
      return 'text-3xl'; 
    case '2xl':
      return 'text-2xl'; 
    case 'lg':
      return 'text-lg';
    case 'base':
      return 'text-base';
    default:
      return 'text-normal';
  }
} 

let getTransform = (transform: Props['transform']) => {
  switch (transform) {
    case 'uppercase':
      return 'uppercase';
    default:
      return 'normal-case';
  }
}

let getMargin = (margin: Props['margin']) => {
  switch (margin) { 
    case 'top':
      return 'mt-1';
    case 'bottom':
      return 'mb-1';
    case 'auto':
      return 'm-auto';
    case 'x-auto':
      return 'mx-auto';
    default:
      return 'my-auto';
  }
} 

let getBg = (bg: Props['bg']) => {
  switch (bg) {
    case 'red':
    return 'bg-getAllRed-100'; 
    case 'orange':
      return 'bg-getAllOrange-100'; 
    case 'green':
      return 'bg-getAllGreen-200';
    case 'white':
      return 'bg-white';
    default:
      return '';
  }
} 

let getRadius = (radius: Props['radius']) => {
  switch (radius) {   
    case 'rounded':
      return 'rounded';
    default:
      return 'rounded-none';
  }
}

let getBorder = (border: Props['border']) => {
  switch (border) {
    case 'tb-grey':
      return 'border-t border-b border-getAllGrey-200';
    case 'lightgrey':
      return 'border border-getAllGrey-200'
    case 'grey':
      return 'border border-getAllGrey-600';
    default:
      return 'border-none';
  }
}

export const Span = ({type, align, padding, weight, transform, margin, size, bg, radius, border, children }: Props) => { 
  return <span className={classnames( getMargin(margin), getfontSize(size), getBg(bg), getRadius(radius),
          getStyle(type), getWeight(weight), getAlign(align), getPadding(padding), getTransform(transform),
          getBorder(border)
          )} >{children}
          </span>
} 