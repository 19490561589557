import { useMemo, useEffect } from 'react'  
import { Button } from '../components/common/Button';   
import { Span } from '../components/common/Span';  
import  { useTable, useFilters, useSortBy, usePagination } from 'react-table'
import { BiSortDown, BiSortUp } from 'react-icons/bi'

import { ColumnFilter } from '../components/ColumnFilter'

const Table = ({ columns, data } : { columns: any; data: any; }) => {

    const tableInstance = useTable({ 
        columns, 
        data, 
        defaultColumn: { Filter: ColumnFilter },
        initialState: {
            sortBy: [
                {
                    id: 'id',
                    desc: true
                }
            ]
        } 
    },
        useFilters, useSortBy, usePagination
    ) 
    

    const { 
        getTableProps, 
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setPageSize,
        state, 
        setHiddenColumns,
        prepareRow, 
    } = tableInstance

    const { pageIndex, pageSize } = state

    useEffect(() => {
        console.log(columns.filter(column => !column.isVisible));
        setHiddenColumns(
          columns.filter(column => !column.isVisible).map(column => column.accessor)
        );
      }, [setHiddenColumns, columns]); 
 
    return (<>
        <table {...getTableProps()} border='0' cellspacing='0' cellpadding='0'>
        <thead>
            {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                    <div className='bg-white flex justify-center font-semibold px-3.5 py-4'>
                        {column.render('Header')}   
                            {column.isSorted
                            ? column.isSortedDesc
                                ? <BiSortDown className='my-auto text-lg mx-2'/>
                                : <BiSortUp className='my-auto text-lg mx-2'/>
                            : ''} 
                    </div>
                    <div className='pt-2 mx-1'>{column.canFilter ? column.render('Filter') : null}</div> 
                </th>
                ))}
            </tr>
            ))}
        </thead>
        
        <tbody {...getTableBodyProps()}> 
            {page.map((row) => {
            prepareRow(row);
            return (
                <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()} className='text-center'>{cell.render('Cell')}</td>;
                    })}
                </tr>
            );
            })}
        </tbody>
    </table> 
    <div className='flex justify-between'>
        <div className='my-4'>
            <select
                className='px-2 rounded border border-getAllGrey-200 page'
                value={pageSize} 
                onChange={(e) => setPageSize(Number(e.target.value))}>
                    {[10,25,50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize} rows
                        </option>
                    ))}
            </select>
        </div>

        <div>
            <Button 
                width='none' padding='px' type='white' radius='left' color='black' border='darkgrey'
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
            >
                Previous
            </Button>
            <Span bg='green' margin='auto' padding='px' type='white' border='tb-grey' size='lg'>  
                {pageIndex + 1} 
            </Span>
            <Button 
                width='none' padding='px' type='white' radius='right' color='black' border='darkgrey'
                onClick={() => nextPage()}
                disabled={!canNextPage}
            >
                Next
            </Button>
        </div> 
    </div>    
    </>);
   }


export default Table;

export const handleMultiSortBy = (column, setSortBy, meinSortBy) => {
    //set sort desc, aesc or none?
    const desc =
      column.isSortedDesc === true
        ? undefined
        : column.isSortedDesc === false
        ? true
        : false;
    setSortBy([{ id: column.id, desc }, ...meinSortBy]);
  };
   
    