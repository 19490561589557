/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { SubContainer } from "./common/Container"; 
 import { Span } from "./common/Span"  
 

export default (props: any) => { 
    return <> 
    <table className='table-white'>
        <tr>
            <th>Item</th>
            <th>Merchant</th>
            <th>Price</th>
            <th>Total</th>
        </tr> 
        
        {props.item.map((items: any)=> (<>
            <tr>
                <td>
                    <Span weight='medium' type='lightgreen' radius='rounded' border='lightgrey' padding='normal'>
                        {`x${items.quantity}`} 
                    </Span>
                    <Span padding='normal'>{items.name}</Span> 
                </td>
                <td className='text-center'>
                    <Span padding='normal' >
                        {items.merchant_name} 
                    </Span>
                </td>
                <td className='text-center'><Span padding='px-normal' >
                    ₱ {parseFloat(items.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Span></td>
                <td className='text-center'><Span padding='px-normal'>
                    ₱ {parseFloat(items.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Span></td>
           </tr>    
           </>) 
        )} 
    </table>  
        
    </>
}