import { GridContainer, Container } from '../../components/common/Container';  
import { Span } from '../../components/common/Span'  
import { Button } from '../../components/common/Button' 
import { Select } from '../../components/common/Select'  
import LocationSuggestion from '../../components/LocationSuggestion';

import * as Yup from 'yup'
import { useFormik } from 'formik'
  
const initialValues = {
    location: '', 
    category: '',
    subcategory: '', 
    product: ''
}

const onSubmit = values => {
  console.log('Form data', values)
}

const validationSchema = Yup.object({  
    location: Yup.string().required('Required!'),
    category: Yup.string().required('Required!'),
    subcategory: Yup.string().required('Required!'), 
    product: Yup.string().required('Required!'),
}) 
export default () => {  

    // Formik
    const formik = useFormik({
        initialValues, 
        onSubmit,
        validationSchema
    })

    return (<>
    <Container bg='white' padding="x">
        <GridContainer bg='white' padding='top-sm'>
            <div className='col-span-3'>
                <Span>Location</Span> 
                <LocationSuggestion /> 
            </div> 

            <div>
                <Span>Category</Span>  
                <Select id='category' {...formik.getFieldProps('category')}>
                    <option value="" selected disabled hidden>-- Select --</option>
                    <option value="Product">Product</option> 
                </Select>
                {formik.touched.category && formik.errors.category? <div className="error">{formik.errors.category}</div> : null}
            </div>
            
            <div>
                <Span>Sub Category</Span>  
                <Select id='subcategory' {...formik.getFieldProps('subcategory')}>
                    <option value="" selected disabled hidden>-- Select --</option>
                    <option value="Product">Product</option> 
                </Select>
                {formik.touched.subcategory && formik.errors.subcategory? <div className="error">{formik.errors.subcategory}</div> : null}
            </div>

            <div>
                <Span>Product</Span>  
                <Select id='product' {...formik.getFieldProps('product')}>
                    <option value="" selected disabled hidden>-- Select --</option>
                    <option value="Product">Product</option> 
                </Select>
                {formik.touched.product && formik.errors.product? <div className="error">{formik.errors.product}</div> : null}
            </div> 

        </GridContainer> 

        <div className='text-right'>
            <Button width='none' padding='px' type='lightgreen' margin='xy' radius='rounded'>Add Location</Button>
            <Button width='none' padding='px' type='grey' radius='rounded'>Cancel</Button>
        </div>
    </Container>
    </>);
}