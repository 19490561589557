import { Link } from 'react-router-dom'
import { ColumnFilter } from "../../../components/ColumnFilter";   
import DeleteMarket from '../DeleteMarket';

export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
    },
    {
        accessor: 'name',
        Header: 'Name',
        Filter: ColumnFilter,
        isVisible: true,        
    },
    {
        accessor: 'banner_image',
        Header: 'Banner',
        Filter: ColumnFilter,
        disableFilters: true,
        isVisible: true,
        Cell: (props) => {
            return (<>
                <img className='column-image'
                     src={props.row.original.banner_image}/>
            </>); 
        }
    },
    {
        accessor: 'action',
        Header: 'Action',
        Filter: ColumnFilter,
        isVisible: true,
        disableFilters: true,
        Cell: data => <div className="flex justify-center">
                <Link to={`market/edit/${data.row.original.id}`} className="edit">Edit</Link>
                {/* <Link to={`market/delete/${data.row.original.id}`} className="delete">Delete</Link> */}
                <DeleteMarket id={data.row.original.id}/>
            </div> 
    }
]