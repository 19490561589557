import { useState } from 'react' 
import { Span } from './common/Span'
import scriptLoader from 'react-async-script-loader'; 
import PlacesAutocomplete from 'react-places-autocomplete';
import { IoSearchOutline } from "react-icons/io5";  

function MapContainer({ isScriptLoaded, isScriptLoadSucceed}){
 
    const [address, setAddress] = useState("")

    const handleChange = (value) => {
        setAddress(value);
    }
    
    const handleSelect = (value) => {
        setAddress(value)
    } 

    if (isScriptLoaded && isScriptLoadSucceed) {
        return (
          <div>
            <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="tw-relative tw-my-3">  
                  <input
                        {...getInputProps({
                        placeholder: "Select Address...",
                        className: "loc-search",
                    })}
                    />
                  <div>
                    {loading ? <div>Loading...</div>: null} 
                    {suggestions.map(suggestion => {   
                        const style =  {
                            backgroundColor: suggestion.active ? "#f2f2f2" : "#ffffff", 
                            cursor: "pointer",
                        };

                        return (
                            <div {...getSuggestionItemProps(suggestion, {style})}>
                                <div className='text-left p-2 border-b border-getAllGrey-200'>
                                    <Span padding='py'>{suggestion.description}</Span>
                                </div>
                            </div> 
                        );
                        })}
                    </div> 
                </div>  
            )} 
            </PlacesAutocomplete>
        </div>  
    ); 
    }else {
        return <div></div>;
    } 
    // }
}

export default scriptLoader([
    `https://maps.googleapis.com/maps/api/js?key=AIzaSyDuGG_q0K0oMYLOtbgXhLJ6HupvZNjc338&libraries=places`,
])(MapContainer);