import { useEffect, useMemo, useState } from 'react' 
import { CenterContainer, SubContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading" 
import { Span } from "../../components/common/Span"   
  
import SubCategoryTab from './SubCategoryTab'

import { COLUMNS } from './Table/column' 
import Table from '../../components/Table'  
import api from '../../network/api'

import Loading from '../../components/Loading'

export default () => {  

    const [loading, setLoading] = useState(true);
    const [subcategory, setSubCategory] = useState(false);
    const toggleSubCategory = (e:any) => {
        e.preventDefault();
        setSubCategory(!subcategory);
    }; 

    const columns = useMemo(() => COLUMNS, [])  
    const [data, setSubCategoryData] = useState([])
    
    useEffect(() => {
      api.get('get-subcategory-list')
            .then(res => { 
                setSubCategoryData(res.data.data);
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    return (<><main>
        {loading ? <>
            <Loading />
        </>:<> 
        <CenterContainer padding="header">
            <H1 weight='medium'>Sub Category</H1> 

            {/* New Category */}
            <div className="toggle-container" onClick={toggleSubCategory}>
                <i className="material-icons my-auto">
                    {subcategory  ? 'expand_more' : 'chevron_right' }
                </i>   
                <Span weight="semibold">New Sub Category</Span>
            </div>
             
            {subcategory ? (
                <>
                    <SubCategoryTab/>
                </>
            ) : null} 
 
            <SubContainer flex='flex' bg='white' border='grey' margin='top' borderWidth='bottom'  padding='normal'> 
                <Span weight="semibold">Sub Category List</Span>
            </SubContainer> 
                
            <Table columns={columns} data={data}/>  

        </CenterContainer> 
        </>}
        </main>
    </>)
}