import * as React from 'react';
import classnames from 'classnames' 

type Props = React.HTMLProps<HTMLLabelElement> & {
  children: React.ReactNode,
  type?: 'white', 
  margin?: 'default' | 'top',
  bg?: 'green' | 'grey' | 'white',
  cursor?: 'pointer',
  border?: 'grey'
}


let getbg = (bg: Props['bg']) => {
    switch (bg) { 
        case 'white':
        return 'bg-white hover:bg-getAllGrey-500 hover:text-getAllGrey-400'
        case 'grey':
        return 'bg-getAllGrey-800 hover:bg-getAllGrey-500' 
        case 'green':
        return 'bg-getAllGreen-200 hover:bg-getAllGreen-100';
        default:
        return '';
    }
}

let getBorder = (border: Props['border']) => {
    switch (border) {  
        case 'grey':
            return 'border border-getAllGrey-900 rounded'  
        default:
            return 'border-none';
    }
}

let getStyle = (type: Props['type']) => {
    switch (type) {  
      case 'white':
        return 'text-white'; 
      default:
        return 'text-getAllBlack-100';
    }
} 
  
let getMargin = (margin: Props['margin']) => {
switch (margin) {   
    case 'top':
        return 'mt-1'
    default:
        return 'm-0';
    }
} 

let getCursor = (cursor: Props['cursor']) => {
    switch (cursor) {   
        case 'pointer':
            return 'cursor-pointer'
        default:
            return 'cursor-default';
        }
    } 
  
export const Label = ({type, margin, bg, cursor, border, children, ...props }: Props) => {  
    return  <label className={classnames('px-5 py-2 text-center', getStyle(type), getMargin(margin), getbg(bg),
                getCursor(cursor), getBorder(border) )}
                {...props}>
                {children}
            </label>
}