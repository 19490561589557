import React from 'react'
import { useParams } from 'react-router'
import { MainContent } from '../components/common/Container'
import CategoryDeletePage from '../containers/Category/DeleteCategory'  
import Navbar from '../containers/Navbar'
import Sidebar from '../containers/Sidebar'
 
const CategoryDelete = () => {

    let params:any = useParams();

    // console.log(params)
    return ( 
        <>  
            <CategoryDeletePage id={params.id}/> 
        </>
    )
}

export default CategoryDelete
