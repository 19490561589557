import { CenterContainer, SubContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading" 
import { Span } from "../../components/common/Span"  
import { useState } from "react";
  
import AttributeTab from './AttributeTab'
import Table from './Table/Table'

export default () => {  

    const [attribute, setAttribute] = useState(false);
    const toggleAttribute = (e:any) => {
        e.preventDefault();
        setAttribute(!attribute);
    }; 

    return (<><main>
        <CenterContainer padding="header">
            <H1 weight='medium'>Attribute</H1> 

            <div className="toggle-container" onClick={toggleAttribute}>
                <i className="material-icons my-auto">
                    {attribute  ? 'expand_more' : 'chevron_right' }
                </i>   
                <Span weight="semibold">New Attribute</Span>
            </div>
             
            {attribute ? (
                <>
                    <AttributeTab/>
                </>
            ) : null}  

            <SubContainer flex='flex' bg='white' border='grey' margin='top' borderWidth='bottom'  padding='normal'> 
                <Span weight="semibold">Attribute List</Span>
            </SubContainer> 
                <Table/>

        </CenterContainer> 
        </main>
    </>)
}