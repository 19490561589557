import {useState} from 'react'
import { ModalContainer } from '../../../components/common/Container'
import { Button } from '../../../components/common/Button' 
import { Span } from '../../../components/common/Span'  
import api from '../../../network/api' 
import { useHistory } from "react-router-dom"; 
import warning from '../../../images/warning.png'
    
    type Props = {
        setUpdateModal: Function, 
        onSubmit: any 

    } 
  
export default ({setUpdateModal, onSubmit, ...props}: Props) => { 
      
    return (<>   
    <ModalContainer bg='white' radius='rounded'> 
        
        <div className="px-20 py-4 whitespace-normal text-center "> 
            <div className='mt-5 mb-2.5'>
                <img src={warning} className='h-16 my-2 mx-auto'/>  
                <Span size='2xl' weight='bold'>Updated</Span>
            </div>
            <Span size='base'>
                Market Successfully Updated
            </Span> 
            
            <div className='text-center'>  
                <Button width='none' padding='px' type='lightgreen' border='green' radius='rounded'
                 onClick={(e) => onSubmit(e)}>
                    Ok
                </Button>
            </div> 
        </div> 
    </ModalContainer> 

  <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
  </>);
}   