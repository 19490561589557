import { useState } from 'react' 
import { ModalContainer } from '../../../components/common/Container'
import { ModalCloseButton, Button } from '../../../components/common/Button'
import { Input } from '../../../components/common/Input'
import { Span } from '../../../components/common/Span'
import { IoClose } from 'react-icons/io5'
import { IoMdUnlock } from 'react-icons/io'  
import OTPModal from './OTPModal'

import * as Yup from 'yup'
import { useFormik } from 'formik'
    
    type Props = {
        setResetModal: Function 
    }

    const initialValues = {
        mobile: ''
    }

    const onSubmit = values => {
    console.log('Form data', values)
    }

    const validationSchema = Yup.object({   
        mobile: Yup.string().required('Required!')
    }) 
  
export default ({setResetModal,...props}: Props) => {
    
   const [modalOTP, setOTPModal] = useState(false); 
   
    // Formik
    const formik = useFormik({
        initialValues, 
        onSubmit,
        validationSchema
    })

    // const handleReset= (e) => {
    //     e.preventDefault();  
    //     setResetModal(false)
    //     setOTPModal(true)
    // }
   
    return (<>   
    <ModalContainer bg='darkgreen'>
        <div className="flex pt-1.5"> 
            <ModalCloseButton onClick={() => setResetModal("")} > 
                <IoClose className="text-2xl"/> 
            </ModalCloseButton>    
            <div className='flex p-6'>
                <IoMdUnlock className='text-2xl my-auto mx-3.5 p-1 bg-white bg-opacity-25 rounded-md'/> 
                <Span size='2xl' type='white'>Reset Password</Span>
            </div> 
        </div> 
        <div className="px-20 whitespace-normal text-left"> 
            <Span size='base' type='white'>
                We will send a verification code to your registered mobile number.
            </Span>

            <div className='mx-auto my-8 text-center text-base'>
                <Span size='lg' type='white'> Enter Mobile number </Span>
                <Input border='bottom' bg='green' type='number' align='center'
                id='mobile' {...formik.getFieldProps('mobile')}/>
                {formik.touched.mobile && formik.errors.mobile? <div className="error">{formik.errors.mobile}</div> : null}
            </div> 

            <a onClick={() => {setOTPModal(true)}}>
                <Button type='white' radius='rounded'>
                    <Span size='lg' type='lightgreen' weight='medium'>Get OTP</Span>
                </Button> 
            </a>
        </div> 
    </ModalContainer>
    
    {modalOTP && <OTPModal setOTPModal={setOTPModal}/>}

  <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
  </>);
}   