/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useMemo, useState } from 'react' 
import { CenterContainer, SubContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading" 
import { Span } from "../../components/common/Span"   

import CategoryTab from './CategoryTab'  

import { COLUMNS } from './Table/column' 
import Table from '../../components/Table'  
import api from '../../network/api'

import Loading from '../../components/Loading'

export default () => { 

    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState(false);
    const toggleCategory = (e:any) => {
        e.preventDefault();
        setCategory(!category);
      }; 

      const columns = useMemo(() => COLUMNS, [])
      const [data, setCategoryData] = useState([])
      
      useEffect(() => {
        api.get("get-category-list")
              .then(res => { 
                  setCategoryData(res.data.data.data);
                  setLoading(false)
              }) 
              .catch(error => {
                  console.log(error)
              })
      }, [])

    return (<><main>
        {loading ? <>
            <Loading />
        </>:<> 
        <CenterContainer padding="header">
            <H1 weight='medium'>Category</H1> 
 
            <div className="toggle-container" onClick={toggleCategory}>
                <i className="material-icons my-auto">
                    {category  ? 'expand_more' : 'chevron_right' }
                </i>   
                <Span weight="semibold">New Category</Span>
            </div>
            
            {category ? (
                <>
                    <CategoryTab/>
                </>
            ) : null}  
            
            <SubContainer flex='flex' bg='white' border='grey' margin='top' borderWidth='bottom' padding='normal'> 
                <Span weight="semibold">Category List</Span>
            </SubContainer> 
                
            <Table columns={columns} data={data}/>  

        </CenterContainer> 
        </>}
        </main>
    </>)
}