import React from 'react'
import { MainContent } from '../components/common/Container'
import SubCategoryEditPage from '../containers/SubCategory/SubCategoryEdit'  
import Navbar from '../containers/Navbar'
import Sidebar from '../containers/Sidebar'
import { useParams } from 'react-router-dom'
 
const SubCategoryEdit = () => {
    
    let params:any = useParams();

    console.log("params", params) 

    return ( 
        <> 
            <Sidebar/>
            <MainContent>
                <Navbar /> 
                <SubCategoryEditPage id={params.id}/>
            </MainContent>
        </>
    )
}

export default SubCategoryEdit
