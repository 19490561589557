/* eslint-disable import/no-anonymous-default-export */
import { useState } from 'react'  
import { GridContainer, InputContainer, Container } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button' 
import { useHistory } from "react-router-dom"; 
 
import img from '../../images/img.jpg' 

import * as Yup from 'yup'
import { useFormik } from 'formik'
import api from '../../network/api';
// import Swal from 'sweetalert2';
  
const initialValues = {
    category: '', 
}

const validationSchema = Yup.object({ 
    category: Yup.string().required('Required!'),
}) 

export default () => { 

    // Upload image 
    const [cover,setCover] = useState("")
    const [previewCover,setPreviewCover] = useState("") 
    const [imgCover,setImgCover] = useState(true);
    const [fileCtgCover, setFileCtgCover] = useState('Choose File'); 

    const [banner,setBanner] = useState("")
    const [previewBanner,setPreviewBanner] = useState("")
    const [imgBanner,setImgBanner] = useState(true); 
    const [fileCtgBanner, setFileCtgBanner] = useState('Choose File');

    const handleCover = (e) => {
        e.preventDefault(); 
        setFileCtgCover(e.target.files[0].name);
        const selectedCover = e.target.files[0]
        setCover(selectedCover)
        const coverPreview = URL.createObjectURL(selectedCover)
        setPreviewCover(coverPreview)
        setImgCover(false)
    } 

    const handleBanner = (e) => {
        e.preventDefault(); 
        setFileCtgBanner(e.target.files[0].name);
        const selectedBanner = e.target.files[0]
        setBanner(selectedBanner)
        const bannerPreview = URL.createObjectURL(selectedBanner)
        setPreviewBanner(bannerPreview)
        setImgBanner(false)
    } 

    // Formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues, 
        onSubmit: () => {},
        validationSchema
    })
    
    const history = useHistory();
    const [error, setError]= useState(null); 
    const onSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('category',  formik.values.category)
        formData.append('thumbnail_image', cover)
        formData.append('banner_image', banner)
        api.post('save-category',formData).then(res => { 
            history.push("/category");
            window.location.reload(); 
        }).catch(error => { 
            setError(error.message);
        }) 
    }

    return (<>
    <form>
    <Container bg='white' padding="x">
        <GridContainer bg='white' padding='top-sm' grid="two">
            <div className='col-span-2'>
                <Span>Category Name</Span>
                <Input type='text' radius='rounded' placeholder='New Category' id='category'
                    {...formik.getFieldProps('category')}/>
                {formik.touched.category && formik.errors.category? <div className="error">{formik.errors.category}</div> : null}
            </div> 
          
        </GridContainer>
        <GridContainer bg='white' padding='none' grid='two'>
            <div>
                <Span>Cover Image</Span>  
                <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'>
                    <Input type='text' border='none' radius='rounded' value={fileCtgCover} disabled/>
                    <span className="browse">
                        <input type="file" name="cover" id="upload_cover" onChange={(e) => handleCover(e)} accept="image/*"/>
                        <label htmlFor="upload_cover" className="cursor-pointer">Browse</label> 
                    </span> 
                </InputContainer>
                
                {imgCover && <img src={img} alt='' className='coverCategory'/>}
                {cover && <img src={previewCover} alt='' className='coverCategory'/>}   
            </div>
            <div>
                <Span>Banner Image</Span>  
                <InputContainer flex='flex' content='between' radius='rounded' border='grey' bg='white' borderWidth='border'>
                    <Input type='text' border='none' radius='rounded' value={fileCtgBanner} disabled/>
                    <span className="browse">
                        <input type="file" name="banner" id="upload_banner" onChange={(e) => handleBanner(e)} accept="image/*"/>
                        <label htmlFor="upload_banner" className="cursor-pointer">Browse</label> 
                    </span>
                </InputContainer>

                {imgBanner && <img src={img} alt='' className='bannerCategory'/>}
                {banner && <img src={previewBanner} alt='' className='bannerCategory'/>}   
            </div>
        </GridContainer>

        <div className='text-right'>
            <Button
                width='none' padding='px' type='lightgreen' margin='xy' radius='rounded' 
                onClick={(e) => onSubmit(e)}
                disabled={!(formik.dirty && formik.isValid)}
            >
                Add Category
            </Button>
            <Button 
                width='none' padding='px' type='grey' radius='rounded'
                onClick={() => window.location.reload()}
            >
                Cancel
            </Button>
        </div>
    </Container>
    </form>
    </>);
}