/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from 'react'  
import { GridContainer, Container } from '../../components/common/Container';  
import { Span } from '../../components/common/Span' 
import { Input } from '../../components/common/Input' 
import { Button } from '../../components/common/Button' 
import { Select } from '../../components/common/Select'
import { useHistory, Link } from 'react-router-dom' 
 
import img from '../../images/img.jpg'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import api from '../../network/api';  
  
const initialValues = {
    title: '',
    description: '',
    price: '',
    quantity: '',
    unit: '',
    category: '',
    subcategory: '', 
    merchant: '',
}

const validationSchema = Yup.object({  
    title: Yup.string().required('Required!'), 
    description: Yup.string(),
    price: Yup.string().required('Required!'),
    quantity: Yup.string().required('Required!'),
    unit: Yup.string().required('Required!'),
    category: Yup.string().required('Required!'),
    subcategory: Yup.string().required('Required!'),
    merchant: Yup.string().required('Required!')
}) 

export default ({setLoading, ...props}:any) => {  
    // Upload image 
    const [file,setFile] = useState("")
    const [preview,setPreview] = useState("")   
    const [imgList,setImgList] = useState(true); 
    const [listSpan,setListSpan] = useState(false); 

    const [cat, setCategory] = useState([])
    const [merch, setMerchant] = useState([])
    const [subcat, setSubcategory] = useState([])  
      
    useEffect(() => {
   
    const category_name = api.get("get-category")
    const merchant_name = api.get("get-merchant")
    
    api.all([ category_name,merchant_name]).then(
        api.spread((...allData) => {
            const category_name = allData[0].data.data.category 
            const merchant_name = allData[1].data.data.merchant
            setCategory(category_name)
            setMerchant(merchant_name) 
        })
    )
    }, [])        

    const handleProduct= (e) => {
        e.preventDefault();  
        const selectedFile = e.target.files[0]
        setFile(selectedFile)
        const filePreview = URL.createObjectURL(selectedFile)
        setPreview(filePreview)
        setImgList(false)
        setListSpan(true)
    }  

    // Formik 
    const formik = useFormik({
        enableReinitialize: true,
        initialValues, 
        onSubmit: () => {},
        validationSchema
    })

    const [error, setError]= useState(null);
    const history = useHistory(); 
    const onSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('title',  formik.values.title)
        formData.append('description',  formik.values.description)
        formData.append('price',  formik.values.price) 
        formData.append('unit',  formik.values.unit)
        formData.append('category', formik.values.category)
        formData.append('subcategory',  formik.values.subcategory)
        formData.append('merchant',  formik.values.merchant)
        formData.append('image', file)
        api.post('save-product',formData).then(res => {  
            history.push("/list"); 
            setLoading(false)
            window.location.reload();
        }).catch(error => { 
            setError(error.message);
        })
    }

    const [categoryname, setCategoryName] = useState('') 

    const handleSelect = (e:any) => {  
        e.preventDefault();    
        const category = e.target.value
        formik.setFieldValue("category", category)
        setCategoryName(category)
    }   

    useEffect(() => {

        if(!categoryname){
            return;
        }

        api.get(`get-category-subcategory/${categoryname}`) 
        .then(res => { 
            setSubcategory(res.data.data.subcategory)
        })
        .catch(error => {
            console.log(error)
        })
    }, [categoryname])
 
    return (<>
    <form>
    <Container bg='white' padding="x">
        <GridContainer bg='white' padding='top-sm' grid='two'>
            <div>
                <Span>Title</Span>
                <Input type='text' radius='rounded' placeholder='Title' id='title'
                    {...formik.getFieldProps('title')}/>
                {formik.touched.title && formik.errors.title? <div className="error">{formik.errors.title}</div> : null}
            </div> 
            <div> 
                <Span>Description</Span>
                <Input type='text' radius='rounded' id='description'
                    {...formik.getFieldProps('description')}/>
                {/* {formik.touched.description && formik.errors.description? <div className="error">{formik.errors.description}</div> : null} */}
            </div> 
            <div> 
                <Span>Price</Span>
                <Input type='number' radius='rounded' id='price'
                    {...formik.getFieldProps('price')}/>
                {formik.touched.price && formik.errors.price? <div className="error">{formik.errors.price}</div> : null}
            </div>  
            <div> 
                <Span>Unit</Span>
                <Input type='text' radius='rounded' id='unit'
                    {...formik.getFieldProps('unit')}/>
                {formik.touched.unit && formik.errors.unit? <div className="error">{formik.errors.unit}</div> : null}
            </div>  

        </GridContainer> 
        <GridContainer bg='white' padding='top-sm'>
            
            <div>
                <Span>Main Category</Span>  
                <Select id='category_name' {...formik.getFieldProps('category')} value={categoryname} onChange={handleSelect}>  
                    <option value="" selected disabled hidden>-- Select --</option> 
                    {cat.map((category:any) => { 
                    return ( <option>{category.name}</option>);
                    })}
                </Select>
                {formik.touched.category && formik.errors.category? <div className="error">{formik.errors.category}</div> : null}
            </div>
            <div> 
                <Span>Sub Category</Span>
                <Select id='subcategory_name' {...formik.getFieldProps('subcategory')}>
                    <option value="" selected disabled hidden>-- Select --</option>
                    {subcat.map((subcategory:any) => { 
                    return ( <option>{subcategory.name}</option>);
                    })}
                </Select>
                {formik.touched.subcategory && formik.errors.subcategory? <div className="error">{formik.errors.subcategory}</div> : null}
            </div>
           
            <div>
                <Span>Merchant</Span>  
                <Select id='merchant' {...formik.getFieldProps('merchant')}>
                    <option value="" selected disabled hidden>-- Select --</option>
                    {merch.map((merchant:any) => { 
                    return ( <option>{merchant.name}</option>);
                    })}
                </Select>
                {formik.touched.merchant && formik.errors.merchant? <div className="error">{formik.errors.merchant}</div> : null}
            </div>
        </GridContainer>

        <GridContainer bg='white' padding='top-sm' grid="four">
            <div>
                <Span>Product Image</Span>
                {file && <img src={preview} alt='' className='product'/>}  
                <input type="file" name="product" id="upload_product" onChange={(e) => handleProduct(e)} accept="image/*"/>
                <label htmlFor="upload_product" className="cursor-pointer"> 
                    {imgList && <img src={img} alt='' className='product'/>}
                    {listSpan && <Span type='lightgreen' weight="medium">+ Change image</Span>}
                </label>  
            </div> 
        </GridContainer>
  

        <div className='text-right'>
            <Button 
                width='none' padding='px' type='lightgreen' margin='xy' radius='rounded' 
                onClick={(e) => onSubmit(e)}
                // disabled={!(formik.dirty && formik.isValid)}
            >
                Add Product
            </Button>
            <Button 
                width='none' padding='px' type='grey' radius='rounded'
                onClick={() => window.location.reload()}
            >
                Cance
            </Button>
        </div>
    </Container>
    </form>
    </>);
}