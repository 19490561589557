import * as React from 'react';
import classnames from 'classnames'  

type Props = {
  children: React.ReactNode,
  align?: 'center' | 'end',
  flex?: 'flex',
  bg?: 'white' | 'green' | 'darkgreen' | 'broken' | 'none',
  padding?: 'none' | 'top' | 'header' | 'top-sm' | 'x' | 'y' | 'normal' | 'xy',
  margin?: 'y' | 'top' | 'tb' | 'normal' | '1.5' | 'topbottom' | 'my-5',
  border?: 'grey' | 'lightgrey',
  borderWidth?: 'bottom' | 'top' | 'border',
  grid?: 'two' | 'four',
  content?: 'between',
  radius?: 'rounded',
  shadow?: 'md',
  textalign?: 'right'
  height?: 'h-60'
} 

let getAlign = (align: Props['align']) => {
  switch (align) {  
    case 'end':
      return 'items-center justify-end';
    case 'center':
      return 'items-center justify-center';
    default:
      return 'items-start justify-start';
  }
}

let getTextAlign = (textalign: Props['textalign']) => {
  switch (textalign) { 
    case 'right':
      return 'text-right' 
    default:
      return 'text-left';
  }
}

let getFlex = (flex: Props['flex']) => {
  switch (flex) { 
    case 'flex':
      return 'flex';
    default:
      return 'flex-none';
  }
}

let getBackground = (bg: Props['bg']) => {
  switch (bg) {  
    case 'none':
      return ''
    case 'broken':
      return 'bg-404 bg-cover bg-no-repeat bg-size'
    case 'darkgreen':
      return 'bg-getAllGreen-200'
    case 'green':
      return 'bg-getAllGreen-100'
    case 'white':
      return 'bg-white';
    default:
      return 'bg-getAllGrey-102';
  }
}
 
let getPadding = (padding: Props['padding']) => {
  switch (padding) {  
    case 'header':
      return 'px-5 pt-8'; 
    case 'y':
      return 'py-2';
    case 'x':
      return 'px-5';
    case 'top-sm':
      return 'py-5';
    case 'top':
      return 'py-10';
    case 'normal':
      return 'py-2 px-5';
    case 'xy':
      return 'p-10';
    case 'none':
      return 'p-0';
    default:
      return 'p-5';
  }
} 

let getMargin = (margin: Props['margin']) => {
  switch (margin) {
    case '1.5':
      return 'my-1.5'
    case 'normal':
      return 'my-3'
    case 'topbottom':
    return 'mt-5 mb-20';
    case 'top':
      return 'mt-5';  
    case 'tb':
      return 'mt-14 mb-8';
    case 'my-5':
    return 'my-5';
    case 'y':
      return 'my-10';
    default:
      return 'my-0';
  }
}

let getBorder = (border: Props['border']) => {
  switch (border) {
    case 'lightgrey':
      return 'border-getAllGrey-200'
    case 'grey':
      return 'border-getAllGrey-600';
    default:
      return 'border-getAllGrey-100';
  }
}

let getBorderWidth = (borderWidth: Props['borderWidth']) => {
  switch (borderWidth) {  
    case 'top':
      return 'border-t'; 
    case 'bottom':
      return 'border-b';
    case 'border':
      return 'border';
    default:
      return 'border-0';
  }
} 

let getRadius = (radius: Props['radius']) => {
  switch (radius) {   
    case 'rounded':
      return 'rounded';
    default:
      return 'rounded-none';
  }
} 

let getGrid = (grid: Props['grid']) => {
  switch (grid) {  
    case 'four':
      return 'lg:grid-cols-4 md:grid-cols-4';
    case 'two':
      return 'lg:grid-cols-2 md:grid-cols-2'; 
    default:
      return 'lg:grid-cols-3 md:grid-cols-3';
  }
}  

let getContent = (content: Props['content']) => {
  switch (content) {  
    case 'between':
      return 'justify-between';
    default:
      return 'relative';
  }
} 

let getShadow = (shadow: Props['shadow']) => {
  switch (shadow) {  
    case 'md':
      return 'shadow-md';
    default:
      return '';
  }
} 

let getHeight = (height: Props['height']) => {
  switch (height) {  
    case 'h-60':
      return 'h-60';
    default:
      return '';
  }
}  
 
export const MainContent =  ({bg, children }: Props) => {
  return <div className={classnames('main-content',  getBackground(bg))}>
            {children}
        </div>
}

export const CenterContainer = ({align, flex, bg, padding, children }: Props) => { 
  return <div className={classnames("min-h-screen",
          getAlign(align), getFlex(flex), getBackground(bg), getPadding(padding))}> 
            {children} 
        </div>
}

export const Container = ({bg, padding, border, borderWidth, margin, radius, shadow, children }: Props) => { 
  return <div className={classnames(getBackground(bg), getPadding(padding), getBorderWidth(borderWidth),
          getBorder(border), getMargin(margin), getRadius(radius), getShadow(shadow))}> 
            {children} 
        </div>
}
 
export const CardContainer = ({ bg, padding, border, children }: Props) => { 
  return <div className={classnames("border-2 border-opacity-30 rounded bg-opacity-40",
          getBackground(bg), getPadding(padding), getBorder(border))}>
            {children} 
        </div>
}
 
export const GridContainer = ({ padding, bg, margin, grid, border, borderWidth, height, children }: Props) => { 
  return <div className={classnames("grid sm:grid-cols-1 gap-5", getBorderWidth(borderWidth),
          getPadding(padding), getBackground(bg), getMargin(margin), getGrid(grid), getBorder(border),
          getHeight(height)
          )}>
            {children} 
        </div>
}

export const SubContainer = ({align, flex, bg, margin, padding, border, borderWidth, content, textalign, children }: Props) => { 
  return <div className={classnames(getMargin(margin), getBorderWidth(borderWidth), getTextAlign(textalign),
          getAlign(align), getFlex(flex), getBackground(bg), getPadding(padding), getBorder(border), getContent(content))}> 
            {children} 
        </div>
}

export const InputContainer = ({flex, bg, border, borderWidth, content, radius, children }: Props) => { 
  return  <div className={classnames(getBorderWidth(borderWidth), getBackground(bg), getRadius(radius),
          getFlex(flex), getBorder(border), getContent(content))}> 
            {children} 
          </div>
}

export const ModalContainer = ({ bg, radius, children }: Props) => {
  return <div className={classnames("flex fixed inset-0 z-50 outline-none focus:outline-none items-center justify-center")}>
            <div className={classnames("lg:w-4/12 md:w-11/12")}>
                <div className={classnames("text-white shadow-lg relative flex flex-col w-full outline-none focus:outline-none", 
                  getBackground(bg), getRadius(radius))}>
                {children}
                </div>
            </div>
          </div>
}