import { Link } from 'react-router-dom'
import { StatusFilter } from './StatusFilter'
import classnames from 'classnames';
import { format } from 'date-fns'

import {ORDER_STATUS} from '../../../constants/order'

export const COLUMNS = [ 
    {
        accessor: 'id',
        Header: 'ID', 
        disableFilters: true,
        isVisible: false,
    },
    {
        accessor: 'order_number',
        Header: 'Order ID', 
        disableFilters: true,
        isVisible: true,
    },
    {
        accessor: 'customer_name',
        Header: 'Customer', 
        isVisible: true,        
    },
    {
        accessor: 'market_name',
        Header: 'Market', 
        isVisible: true,    
    },
    {
        accessor: 'order_place',
        Header: 'Order Placed', 
        isVisible: true, 
        Cell: ({value}) => { return format(new Date(value), 'MMM dd, yyyy hh:mm a')} 
    },
    {
        accessor: 'date_processed',
        Header: 'Date Processed', 
        isVisible: true, 
        Cell: ({value}) => { return format(new Date(value), 'MMM dd, yyyy hh:mm a')} 
    },
    {
        accessor: 'total_amount',
        Header: 'Total Amount',
        type: "currency", 
        isVisible: true,
        Cell: props => <div className="m-auto"> 
            <span>₱ {parseFloat(props.row.original.total_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            </div>        
    },
    {
        accessor: 'status',
        Header: 'Status', 
        disableFilters: true,
        Filter: StatusFilter,
        filter: 'includes', 
        isVisible: true,
        Cell: props => 
        // <Link to={`order/details/${props.row.original.id}`}>
            <span
            className={classnames(  
                props.row.original.status === 'delivered' ? "bg-getAllGreen-200 px-4 status" : null,
                props.row.original.status === 'cancelled' ? "bg-getAllOrange-300 px-3 status" : null, 
            )}
            >
            {ORDER_STATUS[props.row.original.status]}
            </span>
        // </Link>
    }, 
]