import React, { useMemo } from 'react' 
import { InputContainer } from '../../../components/common/Container';  
import { FiTag } from 'react-icons/fi'
import { ORDER_STATUS } from '../../../constants/order';

export const StatusFilter = ({ 
    column: { filterValue, setFilter, preFilteredRows, id },
}) => {
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (<>
        
        <InputContainer flex='flex' bg='white' radius="rounded" border='grey' borderWidth="border">
        <FiTag className='my-auto mx-4 text-2xl text-getAllGreen-200'/> 
        <select className='p-2 focus:outline-none border-none w-full rounded-r-md' name={id} id={id} value={filterValue} onChange={(e) => {
            setFilter(e.target.value || undefined);
        }}>
            <option value="">Status</option> 
            {options.map((option:any, i) => (
            <option key={i} value={option}>
                {ORDER_STATUS[option]}
            </option>
        ))}
        </select>
      </InputContainer> 
    </>);
  }  