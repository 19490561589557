/* eslint-disable import/no-anonymous-default-export */
import { useState } from 'react'
import { CenterContainer, Container, SubContainer } from '../components/common/Container'; 
import { H1, H3 } from '../components/common/Heading' 
import { Span } from '../components/common/Span'  
import { Button } from '../components/common/Button' 
import { Label } from '../components/common/Label' 
import { Link } from 'react-router-dom'; 
import api from '../network/api';
// import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";  

export default () => {   
    const [filename, setFilename] = useState('No file chosen');
    const [file, setFile] = useState(new Blob);

    const handleFile = (e:any) => {
        setFilename(e.target.files[0].name);
        setFile(e.target.files[0]);
    }


    const history = useHistory();

    const [error, setError]= useState(null);
    const handleUpload = (e:any) => { 
        let formData = new FormData()

        formData.append('file', file)
        formData.append('name', "Bulk Product")

        api.post('save-bulk-product',formData) 
        .then((res)=>{  
            // Swal.fire('Saved', 'Success')
            history.push("/list");
        }).catch(error => {
            setError(error.message); 
        })
    }


    return (<><main>
        <CenterContainer padding='xy'>
            <H1 weight='medium'>Bulk Import</H1> 

            <H3 margin='mt-8' type='green' weight='semibold'>Instructions:</H3>
            <ul className='instruction'>
                <li>1. Download the format file and fill it with proper data</li>
                <li>2. You can download the example file to understand how the data must be filled</li>
                <li>3. Once you have downloaded and filled the format file, upload it in the form below and submit.</li>
                <li>4. After uploading products you need to edit them and set product's images and choices.</li>
                <li>5. You can get category and sub-category id from their list, please input the right ids.</li>
            </ul> 

            <Container padding='none' borderWidth='border' border='grey' margin='tb'>
                <SubContainer flex='flex' content='between' bg='white' border='grey' borderWidth='bottom' padding='normal'> 
                    <Span weight="medium">Import Products File</Span>
                    <Link to="/file/product_bulk_format.csv" target="_blank" download>
                        <Button width='none' padding='px' margin='none' type='darkgreen' radius='rounded'>
                            Download Format
                        </Button>
                    </Link>
                </SubContainer>
                <SubContainer flex='flex' bg='white'  padding='normal'>  
                    <input id="file" type="file" name="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleFile}/>
                    <Label htmlFor="file" bg="grey" cursor='pointer' border='grey'>Choose File</Label> 
                    <Label htmlFor="file">{filename}</Label>  
                </SubContainer>
            </Container>

            <div className='text-right'>
            <Button width='none' padding='px' type='lightgreen' margin='xy' radius='rounded' onClick={handleUpload}>Add Product</Button>
            <Button width='none' padding='px' type='grey' radius='rounded'>Cancel</Button>
        </div>
        </CenterContainer> 
        </main>
    </>)
}