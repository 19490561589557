import { useState } from "react";
import DeleteProductModal from './Modal/DeleteProductModal'

const DeleteProduct = ({id,  ...props}:any) => { 
     
   const [modalDelete, setDeleteModal] = useState(false); 

    return ( 
    <div className='flex mx-0.5'>
        <a onClick={() => {setDeleteModal(true);}} className="delete">
            Delete
        </a>

        {modalDelete && <DeleteProductModal setDeleteModal={setDeleteModal} id={id}/>}
    </div>
    )
}

export default DeleteProduct

            