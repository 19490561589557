import { useState } from 'react' 
import { ModalContainer, GridContainer } from '../../../components/common/Container'
import { ModalCloseButton, Button } from '../../../components/common/Button'
import { Input } from '../../../components/common/Input'
import { Span } from '../../../components/common/Span'
import { IoClose } from 'react-icons/io5'
import { IoMdUnlock } from 'react-icons/io'    
import ChangePasswordModal from './ChangePasswordModal'

import * as Yup from 'yup'
import { useFormik } from 'formik'

type Props = {
    setOTPModal: Function 
  }
  
const initialValues = {
    one: '',
    two: '', 
    three: '',
    four: '',
}

const onSubmit = values => {
  console.log('Form data', values)
}

const validationSchema = Yup.object({ 
    one: Yup.string().required('Required!'),
    two: Yup.string().required('Required!'),
    three: Yup.string().required('Required!'),
    four: Yup.string().required('Required!')
}) 


export default ({setOTPModal,...props}: Props) => {
    
   const [modalChangePassword, setChangePasswordModal] = useState(false); 
   
    // Formik
    const formik = useFormik({
        initialValues, 
        onSubmit,
        validationSchema
    })

  return (<>   
    <ModalContainer bg='darkgreen'>
        <div className="flex pt-1.5"> 
            <ModalCloseButton onClick={() => setOTPModal("")} > 
                <IoClose className="text-2xl"/> 
            </ModalCloseButton>    
            <div className='flex p-6'>
                <IoMdUnlock className='text-2xl my-auto mx-3.5 p-1 bg-white bg-opacity-25 rounded-md'/> 
                <Span size='2xl' type='white'>OTP Verification</Span>
            </div> 
        </div> 
        <div className="px-20 whitespace-normal text-left"> 
            <Span size='base' type='white'>
                We will send a verification code to your registered mobile number.
            </Span>

             <div className='mx-auto my-8 text-center text-base'>
                <Span size='lg' type='white'> Enter the OTP</Span>
                <GridContainer grid='four' bg='darkgreen'>   
                    <div>
                        <Input border='bottom' bg='green' type='text' align='center' maxLength={1}
                        id='one' {...formik.getFieldProps('one')}/>
                        {formik.touched.one && formik.errors.one? <div className="error">{formik.errors.one}</div> : null}
                    </div>
                    <div>
                        <Input border='bottom' bg='green' type='text' align='center' maxLength={1}
                        id='two' {...formik.getFieldProps('two')}/>
                        {formik.touched.two && formik.errors.two? <div className="error">{formik.errors.one}</div> : null}
                    </div>
                    <div>
                        <Input border='bottom' bg='green' type='text' align='center' maxLength={1}
                        id='three' {...formik.getFieldProps('three')}/>
                        {formik.touched.three && formik.errors.three? <div className="error">{formik.errors.three}</div> : null}
                    </div>
                    <div>
                        <Input border='bottom' bg='green' type='text' align='center' maxLength={1}
                        id='four' {...formik.getFieldProps('four')}/>
                        {formik.touched.four && formik.errors.four? <div className="error">{formik.errors.four}</div> : null}
                    </div>
                </GridContainer>
             </div> 

            <a onClick={() => {setChangePasswordModal(true);}}>
                <Button type='white' radius='rounded'>
                    <Span size='lg' type='lightgreen' weight='medium'>Verify</Span>
                </Button> 
            </a>  
        </div> 
    </ModalContainer>
    
    {modalChangePassword && <ChangePasswordModal setChangePasswordModal={setChangePasswordModal}/>}

  <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
  </>);
}   