import { CenterContainer } from "../../components/common/Container"; 
import { H1 } from "../../components/common/Heading" 
import { Span } from "../../components/common/Span"   

import CategoryEditTab from './CategoryEditTab' 

export default ({id}:any) => {  

    return (<><main>
        <CenterContainer padding="header">
            <H1 weight='medium'>Edit Category</H1> 

            <div className="toggle-container"> 
                <Span weight="semibold">Category Details</Span>
            </div> 

            <CategoryEditTab id={id}/>
        </CenterContainer> 
        </main>
    </>)
}