import { CenterContainer, SubContainer, GridContainer, Container } from "../../components/common/Container"; 
import { H1, H2, H3 } from "../../components/common/Heading"  
import { Span } from "../../components/common/Span"  
import { Link } from 'react-router-dom'
import { Button } from '../../components/common/Button'  

import ShoppingCart from "../../components/ShoppingCart";
import { BiArrowBack } from 'react-icons/bi'
import { useEffect, useState } from "react";
import api from "../../network/api";
import { ORDER_STATUS } from "../../constants/order";
import moment from "moment";
import CancelModal from './Modal/CancelModal'
import note from '../../images/note.png'
 
import { useHistory } from "react-router-dom"; 

export default (props: any) => {    

    const [data, setData] : any = useState([])
    const [order, setOrder] : any = useState([])
    const [item, setOrderItem] : any = useState([])
    const [customer, setCustomerDetails] : any = useState([])
    const [info, setInfo] : any = useState([])

    useEffect(() => {
      api.get(`get-order-item/${props.id}`)
            .then(res => {
                return res.data.data;
            })
            .then(data => {
                setData(data);
                setOrder(data.order_details);
                setOrderItem(data.order_details.order_item)
                setCustomerDetails(data.customer_details);
                setInfo(data.customer_details.contact_info) 
            })
            .catch(error => {
                console.log(error)
            })
    }, [props.id])

    const history = useHistory();
 
    const onSubmit = (e) => {
        e.preventDefault(); 
 
        api.put(`update-order-status/${props.id}`, {
        action: "Accept Order",
      })
      .then((response) => {
        console.log(response.data)  
        history.push("/order");
      }); 
    } 

    const onOntheWay = (e) => {
        e.preventDefault(); 
 
        api.put(`update-order-status/${props.id}`, {
        action: "on the way",
      })
      .then((response) => {
        console.log(response.data)  
        history.push("/order");
      }); 
    } 

    const onDelivered = (e) => {
        e.preventDefault(); 
 
        api.put(`update-order-status/${props.id}`, {
        action: "item delivered",
      })
      .then((response) => {
        console.log(response.data)  
        history.push("/order");
      }); 
    }  


    const [cancelModal, setCancelModal] = useState(false)
    const handleCancel = (e) => {
        e.preventDefault(); 
        setCancelModal(true);
    }

    return (<><main>
        <form>
        <CenterContainer padding="header"> 
        <Link to='/order'><BiArrowBack className='mb-10 text-lg text-getAllGrey-400'/></Link>
        <H1 weight='medium'>Delivery Details</H1> 
        

        <GridContainer padding='none'>  
                <div className='col-span-2'>
                    <SubContainer flex='flex' bg='green' margin='top' padding='normal'> 
                        <H2 type='white' weight='semibold'>{data.order_number}</H2>  
                    </SubContainer> 
                    <SubContainer bg='white' padding='normal'> 
                        <p className='py-2.5'>
                            <Span weight='semibold' >Market:</Span>  {data.market_name}
                        </p>
                        <p className='pb-2.5'>
                            <Span weight='semibold' margin='top'>Order Date:</Span>  
                            {moment(order.order_date).format('LLL')}
                        </p>
                    </SubContainer> 
                    
                    <Container padding='none' margin='my-5'>  
                        <ShoppingCart item={item}/>
                    </Container>


                    {order.customer_note != "" && (<>
                        <Container padding='normal' bg='white' shadow='md'>  
                            <div className='flex my-1.5'>
                                <img src={note} className='note' />
                                <Span weight='semibold' padding='py'>Customer Note:</Span> 
                            </div>
                            <Span weight='medium'>{order.customer_note}</Span> 
                        </Container>
                    </>)}

                    <Container margin='top' padding='normal' bg='white'>  
                        <SubContainer padding='y' bg='white' textalign='right'> 
                            <Span padding='pr-5' weight='medium'>Subtotal:</Span>    
                            <Span padding='px' weight='medium'>
                                ₱ {parseFloat(data.subtotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Span>  
                        </SubContainer>
                        <SubContainer padding='y' bg='white' textalign='right'> 
                            <Span padding='pr-7' weight='medium'>Pabili Fee:</Span>    
                            <Span padding='px' weight='medium'>
                                ₱ {parseFloat(data.pabili_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Span>  
                        </SubContainer>
                        <SubContainer padding='y' bg='white' textalign='right'> 
                            <Span padding='pr-5' weight='semibold'>Grand Order:</Span>    
                            <Span padding='px' weight='bold' type='green'>
                                ₱ {parseFloat(data.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Span>  
                        </SubContainer>
                    </Container>
 
                    <div className='w-full justify-between mb-5 flex'>  
                        {ORDER_STATUS[data.status] === "New Order" && (<>
                            <div>
                                <Button width='none' padding='px' border='orange' type='orange' radius='rounded'
                                    onClick={handleCancel}
                                >
                                    Cancel Delivery
                                </Button> 
                            </div> 
                            <div>
                                <Button width='none' padding='px' type='lightgreen' radius='rounded' 
                                onClick={(e) => onSubmit(e)}> 
                                    Accept Order 
                                </Button> 
                            </div>
                        </>)} 
                        {ORDER_STATUS[data.status] === "Processing" && (<> 
                            <Button width='none' padding='px' border='orange' type='orange' radius='rounded'
                                onClick={handleCancel}
                            >
                                Cancel Delivery
                            </Button>  
                            <Button width='none' padding='px' type='lightgreen' radius='rounded' 
                                onClick={(e) => onOntheWay(e)}
                            > 
                                On the Way 
                            </Button> 
                        </>)}  
                        {ORDER_STATUS[data.status] === "For Delivery" && (<> 
                            <Button width='none' padding='px' border='orange' type='orange' radius='rounded'
                            onClick={handleCancel}
                            >
                                Cancel Delivery
                            </Button>  
                            <Button width='none' padding='px' type='lightgreen' radius='rounded' 
                            onClick={(e) => onDelivered(e)}> 
                                Item Delivered
                            </Button> 
                        </>)}  
                        
                    </div> 
                </div> 

                {/* SideBar */}
                <Container padding='none' >
                    <Container bg='white' margin='top' padding='x' radius='rounded'>
                        <SubContainer flex='flex' bg='white' border='grey' borderWidth='bottom' padding='normal'> 
                            <H2 weight='semibold' margin='x-auto'>Status</H2>  
                        </SubContainer>
                        <SubContainer flex='flex' bg='white'>  
                            {ORDER_STATUS[data.status] === "Cancelled" ? <> 
                                <Span margin='x-auto' padding='px-normal' bg='orange' type='white' radius='rounded'>
                                    {ORDER_STATUS[data.status]}
                                </Span>
                            </> 
                            :
                            <>
                                <Span margin='x-auto' padding='px-normal' bg='green' type='white' radius='rounded'>
                                    {ORDER_STATUS[data.status]}
                                </Span>
                            </>}  
                        </SubContainer>
                    </Container>
                    
                    <Container bg='white' margin='topbottom' padding='x' radius='rounded'>
                        <SubContainer flex='flex' bg='white' border='grey' borderWidth='bottom' padding='normal'> 
                            <H2 weight='semibold'>Customer Details</H2>  
                        </SubContainer>
                        <SubContainer bg='white'> 
                            <H3 weight='semibold'>{customer.name}</H3>
                            <ul className='details'>
                                <li><Span type='lightgrey' margin='top'>Contact Info:</Span></li>
                                <li><Span weight='medium'>{info.phone}</Span></li>
                                <li> <Span weight='medium'>{info.email}</Span></li>
                            </ul>
                            
                            <ul className='details'>
                                <li><Span type='lightgrey' margin='top'>Delivery Address:</Span></li>
                                <li><Span weight='medium'>{customer.delivery_address}</Span> </li>
                            </ul> 
                            
                        </SubContainer>
                    </Container>
                </Container>
        </GridContainer>  

        </CenterContainer> 
        </form>

        {cancelModal &&
            <CancelModal setCancelModal={setCancelModal}  id={props.id}/>
        }
        </main>
    </>)
}